<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  components: {
  }
}
</script>
<style lang="scss">
  @import "./src/assets/scss/style.scss";

.form-control {
  background: #fff !important;
    border: 1px solid #d7dbda !important;
}
body{
  font-size: 13px !important;
  color: #777D74 !important;
}
.multiselect__option {
  width: 100%; 
}
.res_text {
  display: inline-block;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  cursor: pointer;
}
.res_text:hover {
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}
</style>
