<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Click Logs</h4>
          </template>
    <template>
      <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 2%;margin-right: 2%;">
        <b-form-group label="Click Ids" label-for="clickid">
          <b-form-textarea id="clickid" rows="10" placeholder="Click Ids" v-model="items.clickids"></b-form-textarea>
          <span v-if="allerros.clickids" :class="['label label-danger']">@{{ allerros.clickids[0] }}</span>
        </b-form-group>
        <b-form-group label="Seperator" label-for="seperator">
          <b-form-radio-group v-model="items.seperator" :options="options" @keydown.native.prevent="handleKeyDown"></b-form-radio-group>
          <span v-if="allerros.clickids" :class="['label label-danger']">@{{ allerros.clickids[0] }}</span>
        </b-form-group>
        <b-button type="submit" variant="primary">Submit</b-button>
      </form>
    </template>
    <br/>
          <template>
            <div v-for="(input, index) in rows" :key="`filterInput-${index}`" class="input wrapper flex items-center">
      <table class="table table-bordered">
        <tbody>
          <tr v-for="(value, key, rowIndex) in input" :key="key" :style="{ backgroundColor: rowIndex === 0 ? 'aqua' : '' }">
            <td>{{key}}</td>
            <td>{{prettyPrintedValue(value)}}</td>
          </tr>
        </tbody>
        </table>  <br/>
      </div>
      </template>
    </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Dictionary from '../../Apis/dictionary'
import Common from '../../Apis/common'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
    this.loadingData=false;
     this.getClickData()
  },
  components: {
    Loader
  },
  methods: {
    async submitForm(){
      this.loadingData = true;
      try{
        localStorage.setItem('ClickData', JSON.stringify(this.items))
        let response = await Common.getClickData(this.items)
        if(response.status == 200){
          this.rows=response.data.data;
          this.$toast.info(response.data.message, {
                    position: 'top-center'
                })
          console.log(this.rows)      
        }else{
          console.log(response);
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async getClickData() {
      const ClickLogData = localStorage.getItem('ClickData')
      if(ClickLogData){
      let ClickData = JSON.parse(ClickLogData)
     this.items.clickids = ClickData.clickids;
     this.items.seperator = ClickData.seperator;
     
    }
    },
    prettyPrintedValue(val) {
      try {
        const parsedValue = JSON.parse(val);
        console.log(JSON.stringify(parsedValue, null, 2));
        return JSON.stringify(parsedValue, null, 2); 
      } catch (error) {
        return val; 
      }
    },
},
  data () {
    return {
      rows: [],
      items: {
        clickids:'',
        seperator: 'newline',
      },
      options: [
        { value: 'newline', text: 'Enter' },
        { value: 'comma', text: 'Comma(,)' }
      ],
      allerros:{},
      loadingData:true,
    }
  },
}
</script>
