<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Audit Logs</h4>
          </template>
          <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <b-row>
                  <b-col md="5">
            <label for="date_range">Date Range</label>
          <date-range-picker
                      :opens="opens"  
                        :locale-data="localeData"
                        v-model="dateRange"
                        :show-dropdowns="true" 
                        :auto-apply="true" 
                        style="width:100%;"
                      >
                </date-range-picker>
              </b-col>
                <b-col md="7">
                  <label for="date_range">User</label>
                <multiselect
                    v-model="items.user"
                    :options="users"
                    :multiple="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
            </b-col>
            </b-row>
            <b-button type="submit" variant="primary">Submit</b-button>
              </form>
              <br/>
              <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
    <div v-for="(input, index) in rows" :key="`filterInput-${index}`" class="input wrapper flex items-center">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th style="width: 100px;">Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in input" :key="key">
            <template v-if="key == 'event'">
            <td>Event</td>
            <td>{{value}}</td>
          </template>
          <template v-if="key == 'user'">
            <td>User</td>
            <td>{{value}}</td>
          </template>
          <template v-if="key == 'diff_string'">
            <td>Change</td>
            <td>{{value}}</td>
          </template>
          <template v-if="key == 'used_class'">
            <td>Class</td>
            <td>{{value}}</td>
          </template>
          <template v-if="key == 'ip_address'">
            <td>Ip</td>
            <td>{{value}}</td>
          </template>
          <template v-if="key == 'user_agent'">
            <td>User agent</td>
            <td>{{value}}</td>
          </template>
          <template v-if="key == 'created_at'">
            <td>Date</td>
            <td>{{value}}</td>
          </template>
          </tr>
        </tbody>
        </table>  <br/>
      </div>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Common from '../../Apis/common'
import UserControl from '../../Apis/usercontrol'
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.listUsers()
    this.getAuditLog()
  },
  components: {
    DateRangePicker,Multiselect,Loader
  },
  data () {
    return {
      dateRange:{
        startDate: new Date(),
        endDate: new Date()
      },
      items: {
        user:[],
      },
      users:[],
      rows: [],
      opens: 'right',
      localeData:{
          direction: 'ltr',
          format: 'yyyy/mm/dd',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loadingData:true,
    }
  },
  methods: {
    formatDateToYMD(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async submitForm(){
      this.loadingData = true;
      const transformedJson = {
        sdate: this.formatDateToYMD(this.dateRange.startDate),
        edate:this.formatDateToYMD(this.dateRange.endDate),
        user: this.items.user.map(item => item.value),
        page: this.currentPage,
        perPage: this.perPage
      };
      const data = {
        sdate: this.formatDateToYMD(this.dateRange.startDate),
        edate:this.formatDateToYMD(this.dateRange.endDate),
        user: this.items.user
      };
      try{
        localStorage.setItem('AuditLog', JSON.stringify(data))
        let response = await Common.getAuditLog(transformedJson)
        if(response.status == 200){
          this.rows = response.data.data.data
          this.totalRows = response.data.data.total
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async getAuditLog(){
    const AuditLog = localStorage.getItem('AuditLog')
    if(AuditLog){
    let AuditLogData = JSON.parse(AuditLog)
    this.dateRange.startDate = new Date(AuditLogData.sdate);
    this.dateRange.endDate = new Date(AuditLogData.edate);
     this.items.user = AuditLogData.user;
     
    }
  }, 
    async listUsers(){
      this.loadingData = true;
      try{
        let response = await UserControl.listManagerUser()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.users.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'submitForm',
  },
  computed: {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>