<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <offer-modal
            ref="myModalRef"
            :items = "items"
            :allerros = "allerros"
            @submitForm = "submitForm"
            :deviceType = "deviceType"
            :offerType = "offerType"
            :blockingType = "blockingType"
            :active_status="active_status"
            :convFlow="convFlow"
            :pricingModels="pricingModels"
            :isManager="isManager"
            :isAdmin="isAdmin"
            :isAdvertiser="isAdvertiser"
            :list = "list"
            :CarrierNameList= "CarrierNameList"
            :OSList = "OSList"
            :TargetType = "TargetType"
            ></offer-modal>

            <note-modal
            ref="noteModalRef"
            :items = "note_Details"
            ></note-modal>
            
            <target-modal
            ref="offerModalRef"
            :items = "offer_Target"
            :list = "list"
            :CarrierNameList= "CarrierNameList"
            :OSList = "OSList"
            :TargetType = "TargetType"
            ></target-modal>

            <single-offer-modal
            ref="offerDetails"
            :items = "offer_details"
            :deviceType = "deviceType"
            :offer_targetdata = "offer_targetdata"
            :offerType = "offerType"
            :active_status="active_status"
            :convFlow="convFlow"
            :pricingModels="pricingModels"
            :isManager="isManager"
            :isAdmin="isAdmin"
            :isAdvertiser="isAdvertiser"
            :isRights="isRights"
            :login_id = "login_id"
            :isPublisher="isPublisher"
            :publishers="publishers"
            :links="links"
            ></single-offer-modal>
          <template v-slot:headerTitle>
            <h4 class="card-title">Offers</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" v-if="isPublisher == false" variant="primary">Add New</b-button>

            <vue-csv-downloader v-if="isPublisher == false"
                class="ml-md-4"
                :data="activeOffers"
                :fields="csvFields"
                name="my_data.csv" >
                <b-button variant="primary">Download CSV</b-button>
              </vue-csv-downloader>

              <vue-csv-downloader v-if="isPublisher == true"
                class="ml-md-4"
                :data="PubOffers"
                :fields="pubFields"
                name="my_data.csv" >
                <b-button variant="primary">Download CSV</b-button>
              </vue-csv-downloader>

          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                <div>Total Rows : {{ totalRows}}</div>
                </b-col>
              
                <b-col sm="0" md="3" class="my-1" style="float: right;">
        <b-form-group
          label="Page"
          label-for="per-page-select"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="handleFilterBlur"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
                <b-table bordered hover 
                :items="offer_rows" 
                :fields="columns"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                >
                
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" :style="setWidth(field.key)">
                  <template v-if="field.key === 'advertiser_name'">
                    <multiselect
                    v-model="advertiserNameFilter"
                    :options="[ { value: 'null', text: 'All' }, ...advertisers]"
                    @select="handleFilterBlur"
                    @remove="handleFilterRemove('advertiser')"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
                
                </template>
                <template v-else-if="field.key === 'active'">
                    <b-form-select v-model="filters[field.key]" :options="all_status" @change="handleFilterBlur" prepend-top>
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>
                    <template v-else-if="field.key === 'pricing_model'">
                    <b-form-select v-model="filters[field.key]" :options="pricingModels" @change="handleFilterBlur" prepend-top>
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>
                  <template v-else>
                    <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                  </template>
                  </td>
                </template>

                <template v-slot:cell(int_id)="data">
                    <span v-if="!data.item.editable">{{ data.item.int_id }}</span>
                    <input type="text" v-model="data.item.int_id" v-else class="form-control">
                  </template>
                  <template v-slot:cell(name)="data">
                    <!-- <span v-if="!data.item.editable">{{ data.item.name }}</span> -->
                    <!-- <input type="text" v-model="data.item.name" class="form-control"> -->
                    <a :href="data.item.url" target="_blank" :title="data.item.url">{{ data.item.name }}</a>
                  </template>
                  <template v-slot:cell(pricing_model)="data">
                     <span v-if="!data.item.editable">
                     <span v-for="l in pricingModels" :key="l.value">
                     <span v-if="data.item.pricing_model == l.value">
                       {{ l.text }}
                    </span>
                    </span>
                    </span> 
                      <input type="text" v-model="data.item.pricing_model" v-else class="form-control">
                  </template>
                  <template v-slot:cell(price)="data">
                    <span v-if="!data.item.editable">{{ data.item.price }}</span>
                    <input type="text" v-model="data.item.price" v-else class="form-control">
                  </template>
                  <template v-slot:cell(active)="data"  v-if="isPublisher == false">
                    <b-button variant=" iq-bg-danger" @click="update(data.item)" v-if="data.item.status_text == 'INACTIVE'" size="sm">{{ data.item.status_text}}</b-button>
                    <b-button v-else-if ='data.item.status == 0' disabled>{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-danger" v-else-if ='data.item.status == 4' disabled>{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-success" @click="update(data.item)" v-else size="sm">{{ data.item.status_text}}</b-button>
                  </template>
                  <template v-slot:cell(action)="data" v-if="isPublisher == false">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="singleOffer(data.item)" :disabled="data.item.status_text == 'UNAPPROVED'"><i class="ri-eye-fill m-0" title="View"></i></b-button>
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="clone(data.item)"><i class="ri-book-2-fill m-0" title="Clone"></i></b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="noteList(data.item)"><i class="fas fa-comments" title="Notes"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="offerTarget(data.item)"><i class="fas fa-bullseye" title="OfferTarget"></i></b-button>
                  </template>
                  <template v-slot:cell(action)="data" v-else>
                    <b-button variant=" iq-bg-success mr-1 mb-1" v-if="data.item.promote == 'yes'" size="sm" @click="singleOffer(data.item)"><i class="ri-eye-fill m-0" title="View"></i></b-button>
                     <b-button variant=" iq-bg-success mr-1 mb-1" v-if="data.item.promote == 'yes'" size="sm" @click="copyURL(data.item)"><i class="ri-link-m m-0"></i></b-button>
                     <!-- <b-button variant=" iq-bg-success mr-1 mb-1" v-if="data.item.promote == 'yes'" size="sm" @click="noteList(data.item)"><i  class="fas fa-comments"></i></b-button> -->
                     </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
          <b-col sm="3" md="2" class="my-1" v-if="offer_rows.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import Common from '../../Apis/common'
import OfferModal from './OfferModal.vue'
import TargetModal from './TargetModal.vue'
import SingleOfferModal from './SingleOfferModal.vue'
import Loader from '../../components/Loader.vue'
import VueCsvDownloader from 'vue-csv-downloader'
import UserControl from '../../Apis/usercontrol'
import router from '../../router'
import Multiselect from 'vue-multiselect'
import NoteModal from './NoteModal.vue'
import Dictionary from '../../Apis/dictionary'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.loadData()
  },
  components: {
    OfferModal, Loader,SingleOfferModal,
    router,Multiselect,NoteModal,TargetModal,VueCsvDownloader
},
  data () {
    return {
      columns: [
        { label: 'Id', key: 'int_id', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Pricing_Model', key: 'pricing_model', class: 'text-left' },
        { label: 'Price', key: 'price', class: 'text-left'},
        { label: 'Status', key: 'active', class: 'text-left'},
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      csvFields: ['int_id','name', 'description','url','price','active'],
      pubFields: ['int_id','name', 'description', 'url','price','expire_date','active'],
      offer_rows: [],
      items: {
        _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:'',
        type:null,
        },
       note_Details:{
        note:'',
        name:'',
        user_id:'',
        offer_id:'',
       },
       offer_Target:{
           offer_id:''

       },
       offer_data:{
         country : []
       },
      filters: {
        name: '',
        price: '',
        int_id:'',
        active:'',
        advertiser_name:'',
        pricing_model:'',
      },
      allerros:{},
      deviceType:[],
      offerType:[],
      blockingType:[],
      convFlow:[],
      active_status:[],
      all_status:[],
      pricingModels:[],
      pricingData:[],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      login_id:'',
      isAdmin:false,
      isManager:false,
      isAdvertiser:false,
      isPublisher:false,
      isRights: [],
      loadingData:true,
      advertisers:[],
      publishers:[],
      offer_details:[],
      offer_targetdata:[],
      CarrierNameList:[],
      TargetType:[],
      OSList:[],
      list:[],
      links:'',
      advertiserNameFilter: null
    }
  },
  methods: {
    default () {
      return {
        items:{
          _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:'',
        },
        editable: false,
        allerros:{},
      }
    },
    setWidth(field){
      if(field == 'int_id'){
        return { width: '7%' }
      }
      if(field == 'url'){
        return { width: '10%' }
      }
      if(field == 'advertiser_name'){
        return { width: '20%' }
      }
      if(field == 'price'){
        return { width: '10%' }
      }
      if(field == 'active'){
        return { width: '10%' }
      }
      if(field == 'action'){
        return { width: '25%' }
      }
    },
    async  copyURL(item){
    const url = process.env.VUE_APP_BASE_URL + 'site/click?offer_id=' + item._id + '&user_id=' + this.login_id + '&ref=' + item.int_id;
    try {
      if (navigator.clipboard) {
        // Use navigator.clipboard API if available
        await navigator.clipboard.writeText(url);
      } else {
        // Use document.execCommand() fallback for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = url;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
      this.$toast.info('Promotion URL copied!', {
        position: 'top-center'
      });
    } catch (err) {
      console.error('Failed to copy URL to clipboard: ', err);
    }
  },
    getColums(){
      if(this.isPublisher){
        this.columns=[
        { label: 'Id', key: 'int_id', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Price', key: 'price', class: 'text-left'},
        { label: 'Expired', key: 'expire_date', class: 'text-left'},
        { label: 'Action', key: 'action', class: 'text-center' }
        ]
      }else if(this.isManager || this.isAdmin){
        this.columns=[
        { label: 'Id', key: 'int_id', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Advertiser', key: 'advertiser_name', class: 'text-left' },
        { label: 'Pricing_Model', key: 'pricing_model', class: 'text-left' },
        { label: 'Price', key: 'price', class: 'text-left'},
        { label: 'Status', key: 'active', class: 'text-left'},
        { label: 'Action', key: 'action', class: 'text-center' }
        ];
      }
    },
    edit(item) {
      this.items = item;
      this.allerros={};
      this.$refs.myModalRef.show();
    },
    async clone (item) {
      if(confirm('Are you sure to clone this Offer?')){
        try{
          let response = await Offer.cloneOffer(item)
          if(response.status == 201){
            this.items = response.data.data;
            this.$refs.myModalRef.show();
            this.listOffer()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    showModal(){
      this.allerros = {}
      this.setNext10YearsDate()
      this.$refs.myModalRef.show();
    },
    async noteList(item){
      this.note_Details.offer_id = item._id;
      this.note_Details.user_id = this.login_id;
      this.note_Details.name= item.name;
      this.$refs.noteModalRef.show();
    },
    async offerTarget(item){
      this.offer_Target.offer_id = item._id;
      this.$refs.offerModalRef.show();
    },
    submit (item) {
      this.items = item;
      this.submitForm();
      item.editable = false
    },
    async singleOffer(item){
      this.loadingData = true;
      try{
        let response = await Offer.singleOffer(item)
        if(response.status == 200){
          this.offer_details = response.data.data;
          this.offer_targetdata = response.data.offer_targeting;
          this.offer_details.hosts = process.env.VUE_APP_BASE_URL;
          if (this.isPublisher) {
          this.links = this.offer_details.hosts+"site/click?offer_id="+this.offer_details._id+"&user_id="+this.login_id+"&ref="+this.offer_details.int_id;
          }
          this.$refs.offerDetails.show();
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listPublisher(){
      try{
        let response = await UserControl.listPublisher()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.publishers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
      }catch(error){
      }
    },
    targetoffer(item){
      this.$router.push({ path: 'TargetOffer', params: { id: item._id } })
    },
    async submitForm(){
      this.loadingData = true;
      const formData = new FormData()
      formData.append('name', this.items.name);
      formData.append('description', this.items.description);
      formData.append('url', this.items.url);
      formData.append('price', this.items.price);
      formData.append('pricing_model', this.items.pricing_model);
      formData.append('cap', this.items.cap);
      formData.append('device_type', this.items.device_type);
      formData.append('conv_flow_id', this.items.conv_flow_id);
      formData.append('note', this.items.note);
      formData.append('active', this.items.active);
      formData.append('expire_date', this.items.expire_date);
      formData.append('type', this.items.type);
      formData.append('blocking_type', this.items.blocking_type);
      if (Array.isArray(this.items.country)) {
  this.items.country.forEach(country => formData.append('country[]', country.value));
}  

if (Array.isArray(this.items.os)) {
  this.items.os.forEach(os => formData.append('os[]', os.value));
}

if (Array.isArray(this.items.carrier)) {
  this.items.carrier.forEach(carrier => formData.append('carrier[]', carrier.value));
}


      formData.append('country_status', this.items.country_status);
      formData.append('os_status', this.items.os_status);
      formData.append('carrier_status', this.items.carrier_status);
      if(this.items._id){
        formData.append('_id', this.items._id);
      }
      formData.append('file', this.items.file);
      try{
        let response = await Offer.storeOffer(formData)
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          this.listOffer()
          this.items = this.default()
          this.$refs.myModalRef.hide();
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listOs(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
       
      };
      try{
        let response = await Dictionary.listOs(params)
        if(response.status == 200){
          let data = response.data.data.data
          this.OSList = [];
          let OSList = data.map(item => item.name);
          OSList.map(t=>{
            this.OSList.push({"text":t,"value":t})
           })
          this.loadingData = false;
        }
      }catch(error){
        this.loadingData = false;
      }
    },
    async listCarrier(){
      // this.loadingData = true;
      const params = {
      };
      try{
        let response = await Dictionary.listCarrier(params)
        if(response.status == 200){
          let data = response.data.data.data
          let list = response.data.alpha_list
          this.CarrierNameList = []
           let CarrierNameList =  data.map(item => item.name);
           CarrierNameList.map(t=>{
            this.CarrierNameList.push({"text":t,"value":t})
           })
           this.list  = [];
           list.map(t => this.list.push({"text":t, "value":t}))
          data.forEach((element) => {
            this.loadingData = false;
            
           });
            this.loadingData = false;
        }
      }catch(error){
        this.loadingData = false;
      }
    },
    setRole(){
      const user = localStorage.getItem('user')
      if (user) {
        let role = JSON.parse(user)
        this.login_id=role._id
        this.isRights = role.rights
        this.isManager = role.role == 'manager'
        this.isAdvertiser = role.role == 'advertiser'
        this.isAdmin = role.role == 'admin'
        this.isPublisher = role.role == 'publisher'
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Offer?')){
        try{
          let response = await Offer.deleteOffer(item)
          if(response.status == 200){
            this.listOffer()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    handleFilterBlur() {
      if(this.advertiserNameFilter !== null && typeof this.advertiserNameFilter == 'object'){
          this.filters.advertiser_name = this.advertiserNameFilter.value;
      }
      this.currentPage = 1; // Reset the current page when filtering
        this.listOffer(); // Call listCountry when filter input is blurred
      },
      handleFilterRemove(filterType) {
      if (filterType === 'advertiser') {
        this.advertiserNameFilter = null;
        this.filters.advertiser_name = '';
      }
      this.currentPage = 1;
      this.listOffer();
    },
    async listOffer(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
      try{
        let response = await Offer.listOffer(params)
        if(response.status == 200){
          let data = response.data.data.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
            const status_active = this.all_status.find(x => x.value == element.active);
            element.status_text = status_active ? status_active.text : '';

            if(element.active == 0){
              element.status_text = 'UNAPPROVED';
              element.status = 0;
            }
            if(element.active == 4){
              element.status_text = 'REJECTED';
              element.status = 4;
            }
            if (this.isManager || this.isAdmin) {
              const advertiser = this.advertisers.find(x => x.value == element.user_id);
              element.advertiser_name = advertiser ? advertiser.text : '';
            }
          });
          this.offer_rows = data
          this.totalRows = response.data.data.total
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listStatus(){
      try{
        let response = await Common.listStatus()
        if(response.status == 200){
          let p_model = response.data.pricing_model
          let d_type = response.data.device_type
          let a_status = response.data.offer_status
          let o_status = response.data.all_offer_status
          let offer_type = response.data.offer_type
          let blocking_type = response.data.blocking_type
          let target_type = response.data.target_type
          //pricing model
          for(const key in p_model){
            this.pricingModels.push({ value: key, text: p_model[key] })
          }
        
          //device type
          for(const key in d_type){
            this.deviceType.push({ value: key, text: d_type[key] })
          }

          //active status
          for(const key in a_status){
            this.active_status.push({ value: key, text: a_status[key] })
          }
         
         // all status
         for(const key in o_status){
            this.all_status.push({ value: key, text: o_status[key] })
          }
          //offer type
          for(const key in offer_type){
            this.offerType.push({ value: key, text: offer_type[key] })
          }

          for(const key in blocking_type){
            this.blockingType.push({ value: key, text: blocking_type[key] })
          }

          for(const key in target_type){
            this.TargetType.push({ value: key, text: target_type[key] })
          }
        }
      }catch(error){
      }
    },
    async listAdvertiser(){
      try{
        let response = await UserControl.listAdvertiser()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.advertisers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
      }catch(error){
      }
    },
    setNext10YearsDate() {
      const today = new Date();
      const next10Years = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());
      this.items.expire_date = next10Years.toISOString().substr(0, 10);
      console.log(this.items.expire_date)
    },
    async update(item) {
      this.items._id = item._id;
      this.items.status = item.status;
      try{
        let response = await Offer.updateOfferStatus(this.items)
        if(response.status == 200){
          this.$toast.info('Status Updated Successfully!', {
                    position: 'top-center'
                });
          this.listOffer()
        }else{
          this.allerros = response.data.errors
        }
      }catch(error){

      }
    },
    async activeConvFlow(){
      try{
        let response = await Offer.activeConvFlow()
        if(response.status == 200){
          let data = response.data.convflows
          //store value as a _id and text as a name
          for(const key in data){
            this.convFlow.push({ value: data[key]._id, text: data[key].name })
          }
        }
      }catch(error){
      }
    },
  async loadData() {
  try {
    await this.setRole();
    await this.listStatus();
    await this.activeConvFlow();
    await this.listCarrier();
    await this.listOs();
    await this.listPublisher();
    // if(this.isManager || this.isAdmin){
      await this.listAdvertiser();
    // }
    await this.listOffer();
    await this.getColums();  
  } catch (error) {
    console.error('Error loading data:', error);
  }
},
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listOffer',
  },
  computed: {
    PubOffers() {
      return this.offer_rows.map(offer => {
        const URL = `${process.env.VUE_APP_BASE_URL}site/click?offer_id=${offer._id}&user_id=${this.login_id}&ref=${offer.int_id}`;
        return { ...offer, url: URL };
      });
    },
    activeOffers() {
      return this.offer_rows.filter(offer => offer.active);
    },
    allFieldsValid() {
          return this.items.name;
      },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>
