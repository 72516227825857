import { data } from 'jquery';
import Api from './api';

export default {
    async userType() {
        return await Api.execute('get', 'api/common/user_type');
    },
    async listStatus(){
        return await Api.execute('get', 'api/common/status');
    },
    async getDimension(data){
        return await Api.execute('post', 'api/common/dimension', data);
    },
    async getReport(data){
        return await Api.execute('post', 'api/report', data);
    },
    async getAuditLog(data){
        return await Api.execute('post', 'api/audit',data);
    },
    async getSiteLog(params,data){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/sitelog/'+(queryString ? '?'+queryString : '')+'&'+data); 
    },
    async getClickData(data){
        return await Api.execute('post', 'api/click_data', data);
    },
    async getPostbackData(data){
        return await Api.execute('post', 'api/postback_log', data);
    },
    async getDummyPostbackData(data){
        return await Api.execute('post', 'api/dummy_postback_log', data);
    },
    async updatePostbackData(data){
        return await Api.execute('post', 'api/update_postback_log', data);
    },
    async updateDummyPostback(data){
        return await Api.execute('post', 'api/update_dummy_postback_log', data);
    },
    async testUrl(data){
        return await Api.execute('post', 'api/test_postback_url', data);
    }
}