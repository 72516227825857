<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <loader :isLoading="loadingData" />
            <template v-slot:headerTitle>
              <h4 class="card-title">Site Logs</h4>
            </template>
            <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
              <b-row>
                    <b-col md="3">
              <label for="date_range">Date Range</label>
            <date-range-picker
                        :opens="opens"  
                          :locale-data="localeData"
                          v-model="dateRange"
                          :show-dropdowns="true" 
                          :auto-apply="true" 
                          style="width:100%;"
                        >
                  </date-range-picker>
                </b-col>
                <b-col md="3">
                   <b-form-group label="Extra String:" label-for="extra-string">
                     <b-form-textarea id="extra-string" v-model="items.data" rows="3"></b-form-textarea>
                   </b-form-group>
                </b-col>

              <b-col md="2">
                    <label for="">Collection : </label>
                    <textarea type="text" v-model="items.collection" style="border: 1px solid #cccccc; border-radius: 4px; 
                     padding: 8px; width: calc(100% - 18px); height: 45px; font-size: 14px; resize: none;"/>
              </b-col>
              <b-col md="2">
                   <b-form-group label="Limit:" label-for="extra-string">
                    <b-form-input type="text" v-model="limit"></b-form-input>
                   </b-form-group>
                </b-col>
        <b-col md="2" style="margin-top: 40px;">
                  <label>
                    <input type="checkbox" v-model="isChecked"> <b>Get Count</b>
                  </label>
              </b-col>
              </b-row>
              <b-button  style="margin-top: 20px;"type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              <b-form-textarea style="margin-top: 20px;"
                v-if="responseData === 0 || responseData"
                   rows="100"
                   id="response_data"
                   v-model="formattedResponseData"
                   readonly
              ></b-form-textarea>

                </form>
                <br/>
            <template>
    </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
    
  </template>
  <script>
  import { sofbox } from '../../config/pluginInit'
  import Common from '../../Apis/common'
  import DateRangePicker from 'vue2-daterange-picker'
  import Multiselect from 'vue-multiselect'
  //you need to import the CSS manually
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Loader from '../../components/Loader.vue'

  export default {
    name: 'UiDataTable',
    mounted () {
      sofbox.index()
    },
    components: {
      DateRangePicker,Multiselect,Loader
    },
    data () {
      return {
        dateRange:{
          startDate: new Date(),
          endDate: new Date()
        },
        items: {
         
        },
        rows: [],
        opens: 'right',
        localeData:{
            direction: 'ltr',
            format: 'yyyy/mm/dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 0
        },
        responseData:null,
        totalRows: 0,
        currentPage: 1,
        limit: '',
        loadingData:false,
        isChecked: false
      }
    },
    methods: {
      formatDateToYMD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      async submitForm(){
        this.loadingData = true;
        const transformedJson = {
          sdate: this.formatDateToYMD(this.dateRange.startDate),
          edate:this.formatDateToYMD(this.dateRange.endDate),
          collection : this.items.collection.toLowerCase(),
          limit: this.limit,
          checked: this.isChecked
        };
        try{
          let response = await Common.getSiteLog(transformedJson,this.items.data)
          if(response.status == 200){
            this.responseData = response.data.data
          }else{
            this.allerros = response.data.errors
          }
          this.loadingData = false;
        }catch(error){
          this.loadingData = false;
        }
      },
    },
    watch: {
     // Watch for changes in currentPage and fetchData accordingly
     currentPage: 'submitForm',
    },
    computed: {
     formattedResponseData() {
        if(typeof this.responseData === 'number'){
            return `Total Count is : ${this.responseData}`;
        }
        if (Array.isArray(this.responseData.data)) {
        return this.responseData.data.map((item, index) => {
          const id = item._id?.$oid || "N/A";
          const timestamp = item.updated_at?.$date?.$numberLong || "N/A"; // Use appropriate date field

          // Create a formatted string for the dynamic fields
          const itemDetails = Object.keys(item).map(key => {
            if (key === '_id') {
              return `    [${key}] => MongoId Object\n    (\n        [$id] => ${id}\n    )`;
            }
            if (key === 'updated_at' || key === 'created_at') {
              return `    [${key}] => MongoDate Object\n    (\n        [sec] => ${new Date(parseInt(timestamp)).getTime() / 1000}\n    )`;
            }
            return `    [${key}] => ${JSON.stringify(item[key])}`;
          }).join('\n');

          return `Num: ${index + 1} Time: ${new Date(parseInt(timestamp)).toISOString()}\n` + 
            `Array\n(\n` +
            `${itemDetails}\n` +
            `)\n`;
        }).join('\n');
      }
        return JSON.stringify(this.responseData.data, null, 2);
        },
    allFieldsValid() {
        return this.items.collection;
      },
    }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  .filter:focus-within .multiselect__content-wrapper {
      width: 300px!important;
  }
  </style>