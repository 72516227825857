<template>
  <div>
          <div class="card-header">New Password</div>
           
            <form class="mt-4" v-on:submit="submit">
              <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input type="email" v-model="itmes.email" class="form-control mb-0"  placeholder="Enter email" required>
             </div>
              <div class="form-group">
                  <label for="text">Password</label>
                  <input type="password"  class="form-control" placeholder="" v-model="itmes.password" required>
              </div>
              <div class="form-group">
                  <label for="text">Confirm Password</label>
                  <input type="password"  class="form-control" placeholder="" v-model="itmes.password_confirmation" required>
              </div>
              <p v-if="!passwordsMatch">Passwords do not match</p>
              <button type="submit" class="btn btn-primary"  :disabled="!passwordsMatch">Update</button>
              
            </form>
          </div>
       </div>
  </div>
</template>

<script>
import usercontrol from '../../../Apis/usercontrol';
export default {
  name: "ForgetPassword1",
    data() {
      return {
        itmes:{
        email: '',
        password: '',
        password_confirmation: ''
        },
        successMessage: '',
      }
    },
    computed: {
    passwordsMatch() {
      return this.itmes.password === this.itmes.password_confirmation;
    }
  },
    methods: {
      async submit (event) {
      event.preventDefault();
      try{
        this.successMessage = '';
        const response = await usercontrol.forgetPassword(this.itmes);
        if(response.status == 200){
          this.successMessage = response.data.message;
          this.$router.push('/auth/login');
        }else{
            this.allerros = response.data.errors
          }
      } catch (error) {
        
      }
    
    },
    }
  
}
</script>