<!-- Loader.vue -->
<template>
  <div v-if="isLoading" class="loader-container">
    <div class="loader-wrapper">
      <div class="loader">
        <div class="loader-icon"></div>
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity as needed */
  z-index: 10000000000; /* Ensure the loader is on top of other elements */
}


.loader-icon {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
