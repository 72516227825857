<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Device</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add Device" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <b-form-group label="Device Name" label-for="name">
                  <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group label="Brand" label-for="code">
                  <b-form-input id="brand" type="text" placeholder="Brand Name" v-model="items.brand"></b-form-input>
                  <span v-if="allerros.brand" :class="['label label-danger']">@{{ allerros.brand[0] }}</span>
                </b-form-group>
                <b-form-group label="Model" label-for="model">
                  <b-form-input id="model" type="text" placeholder="Enter Model" v-model="items.model"></b-form-input>
                  <span v-if="allerros.model" :class="['label label-danger']">@{{ allerros.model[0] }}</span>
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="r_offer_filtered" 
              :fields="columns"  
              :current-page="currentPage"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" >
                  <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)"></b-form-input>
                </td>
              </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                  </template>
                  <template v-slot:cell(brand)="data">
                    <span v-if="!data.item.editable">{{ data.item.brand }}</span>
                    <input type="text" v-model="data.item.brand" v-else class="form-control">
                  </template>
                  <template v-slot:cell(model)="data">
                    <span v-if="!data.item.editable">{{ data.item.model }}</span>
                    <input type="text" v-model="data.item.model" v-else class="form-control">
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="r_offer_rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Dictionary from '../../Apis/dictionary'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        name: '',
        brand: '',
        model: '',
        },
        editable: false
      }
    },
    showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    edit(item) {
      item.editable = true
    },
    submit (item) {
      this.items = item;
      this.submitForm();
      item.editable = false
    },
    async submitForm(){
      try{
        let response = await Dictionary.storeDevice(this.items)
        if(response.status == 201){
          console.log(response.message);
          this.listDevice()
          this.items = this.default()
          this.$refs.myModalRef.hide();
        }else{
          console.log(response);
          this.allerros = response.data.errors
        }
      }catch(error){

      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Device?')){
        try{
          let response = await Dictionary.deleteDevice(item._id)
          if(response.status == 200){
            this.listDevice()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    async listDevice(){
      try{
        let response = await Dictionary.listDevice()
        if(response.status == 200){
          let data = response.data.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
          });
          this.r_offer_rows = data
          this.totalRows = this.r_offer_rows.length
        }
      }catch(error){
      }
    }
  },
  data () {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Brand', key: 'brand', class: 'text-left', sortable: true },
        { label: 'Model', key: 'model', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      filters: {
    name: '',
    brand: '',
    model: '',
  },
  r_offer_rows: [],
      items: {
        _id:'',
        name: '',
        brand: '',
        model: '',
      },
      allerros:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  created(){
    this.listDevice()
  },
  computed: {
    allFieldsValid() {
          return this.items.name && this.items.brand && this.items.model;
      },
      r_offer_filtered() {
        if (!Array.isArray(this.r_offer_rows) || this.r_offer_rows.length === 0) {
          return [];
        }
    const r_offer_filtered = this.r_offer_rows.filter(item => {
      return Object.keys(this.filters).every(key =>
        String(item[key].toLowerCase()).includes(this.filters[key].toLowerCase())
      );
    });
    return r_offer_filtered.length > 0
      ? r_offer_filtered
      : [
          Object.keys(this.r_offer_rows[0]).reduce(function(obj, value) {
            obj[value] = '';
            return obj;
          }, {})
        ];
  }
  }
}
</script>
