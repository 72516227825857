import axios from 'axios';
import usercontrol from './usercontrol';

let baseUrl = process.env.VUE_APP_BASE_URL;

var client = axios.create({
  baseURL: baseUrl,
  timeout: (1000 * 5 * 60)
});

// Function to get the Bearer token from local storage
const getBearerToken = () => {
  // Replace 'your_token_key' with the key used to store the token in local storage
  return localStorage.getItem('accessToken');
};

export default {
  async execute(method, uri, data,headers = {}) {
    try {
      let token = getBearerToken();
      if (token) {
        headers = {
          ...headers,
          Authorization: `Bearer ${token}`,
        };
      }
      console.log('headers', headers);
      let req = await client({
        method,
        url: uri,
        withCredentials: true,
        data: data,
        headers: headers, // Include the headers in the request
      });
      return req;
    } catch (err) {
      // console.log('Error in getting Server uri');
      // console.log(err.response);
      // if (err.response.status === 401) {
      //   // usercontrol.logout();
      //   this.$router.push({ name: 'auth1.login' });
      // }
      return err.response;
    }
  },
};
