<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h2 class="card-title">MoboAff-Advertiser S2S Tracking Doc 0.2</h2>
          </template>
          <br />
          <template>
            <form class="mt-2"  style="margin-left: 1%;margin-right: 1%;">
            <b-row>
              <b-col md="11">
              <b-form-group>
                  <b-form-textarea  placeholder="postback link"  type="text" :disabled="true"  v-model="postbacklink"></b-form-textarea>
            </b-form-group>
         </b-col>
       <b-col md="1"> <b-button variant=" iq-bg-success mr-1 mb-1" @click="copyText" size="xl"><i class="ri-file-copy-fill m-0" title="Copy"></i></b-button></b-col>
              
            </b-row>
            </form>
            <div style="margin-left: 2%;" class="mt-3">
              <h3>CONVERSION Tracking :</h3>
              <br />
              <h4>Advertiser side :</h4>
              <p>We generate a unique {clickid} value when your ad is clicked by a user. This {clickid} value
                can be passed through to your Offer-URL by adding Tracking placeholders.</p>
              <p>You must use yourtrackvar=<b>{clickid}</b> as <b>(placeholder)</b> to track clicks and also must need to
                add
                yourpubvar=<b>{pubid}</b> as <b>(placeholder)</b> for track publishers.</p>
              <p>For example: http://yourwebsite.com/landing-page.html?yourtrackid={clickid}&pubid={pubid}</p>
              <h5>PostBack:</h5>
              <p>You must add <b>token</b> for authenticate your conversion, which you can find on your profile page.</p>
              <p>Eventid is used for differentiate conversions like <b>SUB,UNSUB</b> so if you add it as <b>SUB</b> its
                effects on money and <b>UNSUB</b> or any others only effect on conversions not on money.</p>
              <p>List of valid eventids</p>
              <p><b>SUB</b></p>
              <p><b>INSTALL</b></p>
              <p>
                You need to send an <b>HTTP</b> request to the following URL when a conversion occurs:
                Conversion URL <b>http://callback.moboaff.com/site/track.html?clickid={clickid}&token={token}</b>
                you can also add <b>eventid</b> field for tracking event eg. & eventid=INSTALL
              </p>
              <p>For example:
                https://pb.moboaff.com/site/track?clickid={clickid}&token={token}&eventid=SUB</p>

              <p>Note : To track conversion <b>clickid,token,eventid</b> are mendatory field.</p>
              <br />
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template> 
<script>

export default {
  name: 'S2S_adv',
  mounted () {
    this.getUser(),
    this.link()
  },
 data : function(){
  return{
    postbacklink:'',
    token:'',
  }
 },
 
  methods: {
    async copyText(){
      const url = this.postbacklink;
      try {
        if (navigator.clipboard) {
          // Use navigator.clipboard API if available
          await navigator.clipboard.writeText(url);
        } else {
          // Use document.execCommand() fallback for older browsers
          const textarea = document.createElement('textarea');
          textarea.value = url;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
        }
        this.$toast.info('Promotion URL copied!', {
          position: 'top-center'
        });
      } catch (err) {
        console.error('Failed to copy URL to clipboard: ', err);
      }
    },
      link() {
        if (this.token) {
          const token = this.token;
          this.postbacklink = 'https://pb.moboaff.com/site/track?clickid={clickid}&token='+ token +'&eventid=SUB';
         }
    },
    getUser(){
      const user = localStorage.getItem('user')
      if (user) {
        let userdata = JSON.parse(user)
        this.token= userdata.token
        
      }
    },
  },
  
    
};
</script>