<template>
  <b-modal ref="offerModalRef" id="target-modal" size="xl" :style="{ width: '1000px', height: '800px' }" hide-footer>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Offer Targeting</h4>
            </template>
            <form class="mt-4"  @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <template>
              <b-row style="justify-content: center;">
              <label for="T1">Counties:</label>
              <b-col md="5">
               <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="item.country_status" :options="TargetType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
               </b-col>
               <b-col md="5">
                <b-form-group>
                  <label for="country">Country</label>
                <multiselect
                    v-model="item.country"
                    :options="list"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              </b-col>
            </b-row>
              </template>

              <template>
                <b-row style="justify-content: center;">
                <label for="T1">Carrier:</label>
                <b-col md="5">
                <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="item.carrier_status" :options="TargetType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
               </b-col>

               <b-col md="5">
                <b-form-group>
                  <label for="country">Carrier</label>
                 <multiselect
                    v-model="item.carrier"
                    :options="CarrierNameList"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              </b-col>
              </b-row>
              </template>

              <template>
                <b-row style="justify-content: center;">
                <label for="T1">OS:</label>
                <b-col md="5">
                <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="item.os_status" :options="TargetType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="5">
                <b-form-group>
                  <label for="os">OS</label>
              <multiselect
                    v-model="item.os"
                    :options="OSList"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              </b-col>
            </b-row>
              </template>
              <b-button type="submit" variant="primary">Submit</b-button>
            </form>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>


  
  <script>
  import Multiselect from 'vue-multiselect'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Offer from '../../Apis/offer'
  
  export default {
    name: 'OfferTargeting',
    props: ["items", "CarrierNameList","list","OSList", "TargetType"],
    data() {
      return {
      item: {
      carrier: [],
      os:[],
      country: []
      },
      allerros: [],
      OfferTarget: [],
      offerData: []
      };   
    },
    components: {
      Multiselect
    },
    mounted() {

    },
    methods: {
     async submitForm(){
      let submitData = {
            os: this.item.os.map(os => os.value),
            carrier:this.item.carrier.map(carrier => carrier.value),
            country: this.item.country.map(country => country.value),
            offer_id: this.items.offer_id,
            os_status: this.item.os_status,
            country_status: this.item.country_status,
            carrier_status: this.item.carrier_status
          };
        try{
          
          const response = await Offer.storeOfferTarget(submitData);
        if(response.status == 200){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
             
          this.$refs.offerModalRef.hide();
        }else{
          this.allerros = response.data.errors
        }
        
      }catch(error){
        
      }
      },
      handleModalShown() {
      },
      async listOfferTarget(){
        
        try {
    const response = await Offer.getOfferTarget();
    if (response.status === 200) {
      this.OfferTarget = response.data.data;
      
      this.OfferTarget.forEach(item => {
        // Perform the desired operation on each item
        const offerData = item.offer_id === this.items.offer_id ? item : null;
        if (offerData) {
          this.item.country_status= item.country_status;
          this.item.carrier_status= item.carrier_status;
          this.item.os_status= item.os_status;
          if (!Array.isArray(this.item.country)) {
                        this.item.country = [];
                    }
                    item.country.forEach(t => {
                        if (!this.item.country.find(e => e.value === t)) {
                            this.item.country.push({ value: t, text: t });
                        }
                    });

                    if (!Array.isArray(this.item.os)) {
                        this.item.os = [];
                    }
                    item.os.forEach(t => {
                        if (!this.item.os.find(e => e.value === t)) {
                            this.item.os.push({ value: t, text: t });
                        }
                    });

                    if (!Array.isArray(this.item.carrier)) {
                        this.item.carrier = [];
                    }
                    item.carrier.forEach(t => {
                        if (!this.item.carrier.find(e => e.value === t)) {
                            this.item.carrier.push({ value: t, text: t });
                        }
                    });
        }
      });
      this.allErrors = response.data.errors;
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }

      },
      show() {
        this.listOfferTarget();
        this.$refs.offerModalRef.show();
      },
      hide() {
        this.$refs.offerModalRef.hide();
      }
    },
    computed: {
      
    }
  };
  </script>
  <style>
  label[for="T1"] {
    font-weight: bold;
  }
  </style>
 
  
  
 