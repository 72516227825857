<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Publisher Events</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Set Publisher Event" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
              <b-form-group>
                <label for="usertype">publisher</label><span class="text-danger"> *</span>
                <multiselect
                    v-model="items.pub_id"
                    :options="publishers"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              
                <b-form-group label="name" label-for="name">
                  <b-form-input id="name" type="text" placeholder="name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group>
                <label for="usertype">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.status" :options="offer_status" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="handleFilterBlur"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="pub_name_rows" 
              :fields="columns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" >
                  <template v-if="field.key === 'user'">
                    <multiselect
                    v-model="publisherNameFilter"
                    :options="[ { value: 'null', text: 'All' }, ...publishers ]"
                    @select="handleFilterBlur"
                    @remove="handleFilterRemove('publisher')"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
                </template>
                <template v-else-if="field.key === 'status'">
                    <b-form-select v-model="filters[field.key]" :options="offer_status" @change="handleFilterBlur" prepend-top>
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>
                  <template v-else>
                    <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                  </template>
                </td>
              </template>
                  <template v-slot:cell(user)="data">
                    <span>{{ data.item.user }}</span>
                  </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                    <span v-if="allerros.name && data.item.editable" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-button variant=" iq-bg-danger" @click="update(data.item)" v-if="data.item.status_text == 'INACTIVE'" size="sm">{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-success" @click="update(data.item)" v-else size="sm">{{ data.item.status_text}}</b-button>
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="pub_name_rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import UserControl from '../../Apis/usercontrol'
import Common  from '../../Apis/common'
import Loader from '../../components/Loader.vue'
import Multiselect from 'vue-multiselect'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
    this.loadData()
  },
  components: {
    Loader,Multiselect
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        pub_id: null,
        name: '',
        status:'',
        },
        editable: false,
        allerros:{},
      }
    },
    showModal(){
      this.items=this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    edit(item) {
      if(this.editable_master == true){
          return false;
        }
      this.editable_master = true;
      item.editable = true
      this.allerros = {}  
      this.pub_name_rows.forEach((element) => {
        if(element._id != item._id){
          element.editable = false
        }
      });
    },
    submit (item) {
      this.items = item;
      this.submitForm();
    },
    async submitForm(){
      this.loadingData = true;
      this.items.pub_id = this.checkValue(this.items.pub_id);
      try{
        let response = await UserControl.storePubEvents(this.items)
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          console.log(response.message);
          this.listPubEvents()
          this.default()
          this.$refs.myModalRef.hide();
          this.editable_master = false;
        }else{
          console.log(response);
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    checkValue(value){
      if(value.value){
        return value.value
      }else{
        return value
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this ?')){
        try{
          let response = await UserControl.deletePubEvents(item._id)
          if(response.status == 200){
            this.listPubEvents()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    handleFilterBlur() {
      if(this.publisherNameFilter !== null && typeof this.publisherNameFilter == 'object'){
          this.filters.user = this.publisherNameFilter.value;
      }
      this.currentPage = 1; // Reset the current page when filtering
        this.listPubEvents(); // Call listCountry when filter input is blurred
      },
      handleFilterRemove(filterType) {
      if (filterType === 'publisher') {
        this.publisherNameFilter = null;
        this.filters.user = '';
      } 
      this.currentPage = 1;
      this.listPubEvents();
    },
    async listPubEvents(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters
        };
      try{
        let response = await UserControl.listPubEvents(params)
        if(response.status == 200){
          let data = response.data.data.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
            const userD = this.publishers.find(x => x.value == element.pub_id);
            element.user = userD ? userD.text : '';
            const offerD = this.offers.find(x => x.value == element.offer_id);
            element.offer = offerD ? offerD.text : '';
            const statusD = this.offer_status.find(x => x.value == element.status);
            element.status_text = statusD ? statusD.text : '';
          });
          this.pub_name_rows = data
          this.totalRows = response.data.data.total
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listPublisher(){
      try{
        let response = await UserControl.listPublisher()
        if(response.status == 200){
          let data = response.data.data
          console.log(data);
          for(const key in data){
            this.publishers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
      }catch(error){
      }
      console.log('publishers',this.publishers);
    },
    async listActiveOffer(){
      try{
        let response = await Offer.listActiveOffer()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.offers.push({ value: data[key]._id, text:data[key].int_id+'-'+data[key].name })
          }
        }
      }catch(error){
      }
      console.log('offers',this.offers);
    },
    async getUserStatus(){
      try {
        let response = await Common.listStatus()
        let responseData = response.data.pubOfferPriceStatus;
        for (const key in responseData) {
          this.offer_status.push({ value: key, text: responseData[key] });
        }
        console.log(this.offer_status)
      } catch (e) {
        console.log(e)
      }
    },
    async update(item) {
      this.items._id = item._id;
      this.items.status = item.status;
      try{
        let response = await UserControl.updatePubEventsStatus(this.items)
        if(response.status == 200){
          this.$toast.info('Status Updated Successfully!', {
                    position: 'top-center'
                });
          this.listPubEvents()
        }else{
          this.allerros = response.data.errors
        }
      }catch(error){

      }
    },
  async loadData() {
    try {
      await this.getUserStatus();
      await this.listPublisher();
      await this.listActiveOffer();
      await this.listPubEvents();
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }
  },
  data () {
    return {
      columns: [
        { label: 'Publisher', key: 'user', class: 'text-left', sortable: true },
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'status', key: 'status', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      filters: {
        user: '',
        offer: '',
        name: '',
      },
      pub_name_rows: [],
      items: {
        _id:'',
        offer_id: null,
        pub_id: null,
        name: '',
        status:'',
      },
      allerros:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      publishers:[],
      offers:[],
      offer_status:[],
      editable_master:false,
      loadingData:true,
      publisherNameFilter: null
    }
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listPubEvents'
  },
  computed: {
    allFieldsValid() {
          return this.items.pub_id && this.items.name && this.items.status;
      },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>
