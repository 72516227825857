import Api from './api';

export default {
    async listCountry(params){
        //pass params as a query string
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/dictionary/listcountry'+(queryString ? '?'+queryString : ''));
    },

    async listBrowser(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/dictionary/listbrowser'+(queryString ? '?'+queryString : ''));
    },

    async listDevice(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/dictionary/listdevice'+(queryString ? '?'+queryString : ''));
    },

    async listOs(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/dictionary/listos'+(queryString ? '?'+queryString : ''));
    },

    async listCarrier(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/dictionary/listcarrier'+(queryString ? '?'+queryString : ''));
    },

    async listEvents(params){
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        return await Api.execute('get', 'api/dictionary/listevents'+(queryString ? '?'+queryString : ''));
    },

    async storeCountry(data){
        return await Api.execute('post', 'api/dictionary/country', data);
    },

    async storeBrowser(data){
        return await Api.execute('post', 'api/dictionary/browser', data);
    },

    async storeDevice(data){
        return await Api.execute('post', 'api/dictionary/device', data);
    },

    async storeOs(data){
        return await Api.execute('post', 'api/dictionary/os', data);
    },

    async storeCarrier(data){
        return await Api.execute('post', 'api/dictionary/carrier', data);
    },

    async storeEvent(data){
        return await Api.execute('post', 'api/dictionary/event', data);
    },

    async deleteCountry(id){
        return await Api.execute('delete', `api/dictionary/deletecountry/${id}`);
    },

    async deleteBrowser(id){
        return await Api.execute('delete', `api/dictionary/deletebrowser/${id}`);
    },

    async deleteDevice(id){
        return await Api.execute('delete', `api/dictionary/deletedevice/${id}`);
    },

    async deleteOs(id){
        return await Api.execute('delete', `api/dictionary/deleteos/${id}`);
    },

    async deleteCarrier(id){
        return await Api.execute('delete', `api/dictionary/deletecarrier/${id}`);
    },

    async deleteEvent(id){
        return await Api.execute('delete', `api/dictionary/deleteevent/${id}`);
    },

    async updateEventStatus(data){
        return await Api.execute('post', 'api/dictionary/updateeventstatus', data);
    },

    async getCountries(){
        return await Api.execute('get','api/get_countries');
    }
}