<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <loader :isLoading="loadingData" />
            <template v-slot:headerTitle>
              <h4 class="card-title">Country</h4>
            </template>
            <template v-slot:headerAction>
              <b-button @click="showModal" variant="primary">Add New</b-button>
              <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add Country" hide-footer>
                <form class="mt-4" @submit.prevent="submitForm">
                  <b-form-group label="Country Name" label-for="name">
                    <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                    <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                  </b-form-group>
                  <b-form-group label="Country Code" label-for="code">
                    <b-form-input id="countrycode" type="text" placeholder="Enter Country Code" v-model="items.countrycode"></b-form-input>
                    <span v-if="allerros.countrycode" :class="['label label-danger']">@{{ allerros.countrycode[0] }}</span>
                  </b-form-group>
                  <b-form-group label="Alpha 2" label-for="alpha2">
                    <b-form-input id="alpha_2" type="text" placeholder="Enter alpha 2" v-model="items.alpha_2"></b-form-input>
                    <span v-if="allerros.alpha_2" :class="['label label-danger']">@{{ allerros.alpha_2[0] }}</span>
                  </b-form-group>
                  <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
                </form>
              </b-modal>
            </template>
            <template>
              <b-row>

                <b-col md="12" class="table-responsive">
                  <b-col sm="0" md="3" class="my-1" style="float: left;">
                <div>Total Rows : {{ totalRows}}</div>
              </b-col>
                <b-col sm="0" md="3" class="my-1" style="float: right;">
        <b-form-group
          label="Page"
          label-for="per-page-select"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="handleFilterBlur"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
                <b-table bordered hover 
                :items="rows" 
                :fields="columns"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                >
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" >
                    <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                  </td>
                </template>
                    <template v-slot:cell(name)="data">
                      <span v-if="!data.item.editable">{{ data.item.name }}</span>
                      <input type="text" v-model="data.item.name" v-else class="form-control">
                      <span v-if="allerros.name && data.item.editable" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                    </template>
                    <template v-slot:cell(countrycode)="data">
                      <span v-if="!data.item.editable">{{ data.item.countrycode }}</span>
                      <input type="text" v-model="data.item.countrycode" v-else class="form-control">
                      <span v-if="allerros.countrycode && data.item.editable" :class="['label label-danger']">@{{ allerros.countrycode[0] }}</span>
                    </template>
                    <template v-slot:cell(alpha_2)="data">
                      <span v-if="!data.item.editable">{{ data.item.alpha_2 }}</span>
                      <input type="text" v-model="data.item.alpha_2" v-else class="form-control">
                      <span v-if="allerros.alpha_2 && data.item.editable" :class="['label label-danger']">@{{ allerros.alpha_2[0] }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                       <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </template>
            <template>
          <b-col sm="3" md="2" class="my-1" v-if="rows && rows.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
    
  </template>
  <script>
  import { sofbox } from '../../config/pluginInit'
  import Dictionary from '../../Apis/dictionary'
  import Loader from '../../components/Loader.vue'

  export default {
    name: 'UiDataTable',
    mounted () {
      sofbox.index(),
      this.listCountry()
    },
    components: {
    Loader
  },
    methods: {
      default () {
        return {
          items:{
          _id:'',
          name: '',
          countrycode: '',
          alpha_2: '',
          },
          editable: false,
          allerros:{},
        }
      },
      showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
      edit(item) {
        if(this.editable_master == true){
          return false;
        }
        this.editable_master = true;
        item.editable = true
        this.allerros = {}  
        this.rows.forEach((element) => {
          if(element._id != item._id){
            element.editable = false
          }
        });
      },
      submit (item) {
        this.items = item;
        this.submitForm();
        
      },
      async submitForm(){
        this.loadingData = true;
        try{
          let name = this.items.name.charAt(0).toUpperCase() + this.items.name.slice(1);
          this.items.name = name;
          let response = await Dictionary.storeCountry(this.items)
          if(response.status == 201){
            const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
            console.log(response.message);
            if(this.editable_master == false){
              this.currentPage = 1;
            }
            this.listCountry()
            this.items = this.default()
            this.allerros={},
            this.$refs.myModalRef.hide();
            this.editable_master=false;
            this.loadingData = false;
          }else{
            console.log(response);
            this.allerros = response.data.errors
            this.loadingData = false;
          }
        }catch(error){
          this.loadingData = false;
        }
        this.loadingData = false;
      },
      async remove (item) {
        if(confirm('Are you sure to delete the country?')){
          try{
            let response = await Dictionary.deleteCountry(item._id)
            if(response.status == 200){
              this.listCountry()
            }else{
              this.allerros = response.data.errors
            }
          }catch(error){
          }
        }
      },
      handleFilterBlur() {
        this.currentPage = 1; // Reset the current page when filtering
        this.listCountry(); // Call listCountry when filter input is blurred
      },
      async listCountry(){
        this.loadingData = true;
        const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
        try{
          let response = await Dictionary.listCountry(params)
          if(response.status == 200){
            let country_data = response.data.data.data
            //add editable and incremental id in each row
            country_data.forEach((element) => {
              element.editable = false
            });
            this.rows = country_data
            this.totalRows = response.data.data.total
            this.perPage = response.data.data.per_page
            console.log(this.rows);
            console.log(this.totalRows);
            this.loadingData = false;
          }
        }catch(error){
          this.loadingData = false;
        }
        this.loadingData = false;
      },
    },
    watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listCountry',
  },
    data () {
      return {
        columns: [
          { label: 'Name', key: 'name', class: 'text-left', sortable: true },
          { label: 'Country Code', key: 'countrycode', class: 'text-left', sortable: true },
          { label: 'Alpha 2', key: 'alpha_2', class: 'text-left', sortable: true },
          { label: 'Action', key: 'action', class: 'text-center' }
        ],
        filters: {
      name: '',
      countrycode: '',
      alpha_2: '',
    },
        rows: [],
        items: {
          _id:'',
          name: '',
          countrycode: '',
          alpha_2: '',
        },
        allerros:{},
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10,20,50,100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        editable_master:false,
        loadingData: true
      }
    },
    computed: {
      allFieldsValid() {
            return this.items.name && this.items.countrycode && this.items.alpha_2;
        }
      }
  }
  </script>
  