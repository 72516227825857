<template>
  <div>
    <Loader/>
    <vue-scroll-progress-bar @complete="handleComplete" height="0.2rem" backgroundColor="linear-gradient(to right, #067bfe, #0885ff)" style="z-index: 10000" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="sidebar" :logo="logo" />
      <!-- TOP Nav Bar -->
      <NavBarStyle1 title="" :homeURL="{ name: '/' }" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item iq-full-screen"><a href="#" class="iq-waves-effect" id="btnFullscreen"><i class="ri-fullscreen-line"></i></a></li>
            <li><a href="#"  @click="reloadPage" class="iq-waves-effect"><i class="fas fa-sync-alt"></i></a></li>

          </ul>
        </template>
        <template slot="right">
          <div ref="elementToDetectOutsideClick">
          <ul class="navbar-list">
            <li>
              <a @click="showProfile" class="search-toggle bg-primary text-white">
                <img :src="userProfile" class="img-fluid rounded" alt="user">
              </a>
              <div v-if="isProfile" class="iq-show">
              <div class="iq-sub-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white line-height">{{ this.user.username}} - {{ this.user.email}}</h5>
                      <span class="text-white font-size-12">Available</span>
                    </div>
                    <router-link :to="{ name: 'user.edit', query: { id: login_id }}" class="iq-sub-card iq-bg-primary-danger-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-danger">
                          <i class="ri-account-box-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">Account settings</h6>
                          <p class="mb-0 font-size-12">Manage your account parameters.</p>
                        </div>
                      </div>
                    </router-link>
                    <div class="d-inline-block w-100 text-center p-3">
                      <a class="iq-bg-danger iq-sign-btn"  href="#" @click="logout" role="button">Sign out<i class="ri-logout-box-line ml-2"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           </li>
          </ul>
        </div>
        </template>
      </NavBarStyle1>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <transition name="router-anim">
          <router-view/>
        </transition>
      </div>
    </div>
    <FooterStyle1>
      <template v-slot:left>
        <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
        <li class="list-inline-item"><a href="#">Terms of Use</a></li>
      </template>
      <template v-slot:right>
        Copyright {{ currentYear }} <a href="#">Moboaff</a> All Rights Reserved.
      </template>
    </FooterStyle1>
  </div>
</template>
<script>
import Loader from '../components/sofbox/loader/Loader'
import SideBarStyle1 from '../components/sofbox/sidebars/SideBarStyle1'
import NavBarStyle1 from '../components/sofbox/navbars/NavBarStyle1'
import  AdminSideBar from '../SideBar/admin'
import ManagerSideBar from '../SideBar/manager'
import AdvertiserSideBar from '../SideBar/advertiser'
import PublisherSideBar from '../SideBar/publisher'
import profile from '../assets/images/user/1.jpeg'
import logo from '../assets/images/logo.png'
import { sofbox } from '../config/pluginInit'
import { mapGetters } from 'vuex'
import UserControl from '../Apis/usercontrol'

export default {
  name: 'Layout1',
  components: {
    Loader, SideBarStyle1, NavBarStyle1
  },
  mounted () {
    sofbox.mainIndex()
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
   document.removeEventListener('click', this.handleClickOutside);
  },
  
  computed: {
    ...mapGetters({
      showNavTicket: 'TicketBooking/navTicket',
      numberOfTicket: 'TicketBooking/numberOfTicket'
    })
  },
  watch: {
  },
  // sidebarTicket
  data () {
    return {
      sidebar: {},
      userProfile: profile,
      logo: logo,
      user: {},
      isAdmin: false,
      isManager: false,
      isAdvertiser: false,
      isPublisher: false,
      login_id: '',
      currentYear: new Date().getFullYear(),
      isProfile: false,
      userRights: []
    }
  },
  methods: {
    handleClickOutside(event) {
    if (this.$refs.elementToDetectOutsideClick && !this.$refs.elementToDetectOutsideClick.contains(event.target)) {
      this.isProfile = false
    }
  },
  reloadPage() {
      window.location.reload();
  },
    handleComplete () {},
    showProfile(){
      this.isProfile = !this.isProfile
    },
    rightSideBar () {
      if (this.numberOfTicket > 0) {
        this.$store.dispatch('TicketBooking/displaySidebarTicket', true)
      }
    },
    logout () {
      localStorage.removeItem('user')
      UserControl.logout()
      this.$router.push({ name: 'auth1.login' })
    },
    getLocalStorage () {
      const user = localStorage.getItem('user')
      if (user) {
        this.user = JSON.parse(user)
        this.login_id = this.user._id
        this.isAdmin = this.user.role == 'admin'
        this.isManager = this.user.role == 'manager'
        this.isAdvertiser = this.user.role == 'advertiser'
        this.isPublisher = this.user.role == 'publisher'
        if(this.isAdmin){
          this.sidebar = AdminSideBar
        } else if(this.isManager){
          this.sidebar = ManagerSideBar
        } else if(this.isAdvertiser){
          this.sidebar = AdvertiserSideBar
        } else if(this.isPublisher){
          this.sidebar = PublisherSideBar
        }
        
        if(this.isAdmin == false){
          let rights_list = this.user.rights;
              for (const key in rights_list) {
                if(rights_list[key] == 'Offer_Approved'){
                  let arroved={
              "title": "Unapproved Offers",
              "name": "offers.unapproved",
              "is_active": false,
              "link": {
                "name" : "offers.unapproved"
              },
              "class_name": "",
              "is_icon_class": true,
               "icon": "ri-list-settings-line",
            };
            this.sidebar.push(arroved);
          }else if (rights_list[key] == 'User_Active' ){
            let user_active ={
              "title": "Users",
              "name": "user.userList",
              "is_active": false,
              "link": {
                "name" : "user.list"
              },
              "class_name": "",
              "is_icon_class": true,
               "icon": "ri-file-user-line",
            }
            this.sidebar.push(user_active);
          }else if (rights_list[key] == 'Dummy_Postback' ){
            let dummy_Postback ={
              
          "title": "Logs",
          "name": "logs.logs",
          "is_heading": false,
          "is_active": false,
          "link": "",
          "class_name": "",
          "is_icon_class": true,
          "icon": "ri-store-line",
          "children": [
            {
              "title": "Dummy Postback Logs",
              "name": "logs.dummypostback",
              "is_active": false,
              "link": {
                "name" : "logs.dummypostback"
              },
              "class_name": "",
              "is_icon_class": false,
              "icon": ""
            },
          ]
              }
            this.sidebar.push(dummy_Postback);
          }else if(rights_list[key] == 'User_Relationship'){
            let userRelationship = {
              "title": "User Relation",
              "name": "user.userEdit",
              "is_active": false,
              "link": {
                "name" : "user.relation"
              },
              "class_name": "",
              "is_icon_class": true,
               "icon": "ri-file-user-line",
            }
            this.sidebar.push(userRelationship);
          }else if(rights_list[key] == 'Upload_Domain'){
            let upload_domain = {
              "title": "Upload Domain",
              "name": "offers.uploaddomain",
              "is_active": false,
              "link": {
                "name" : "offers.uploaddomain"
              },
              "class_name": "",
              "is_icon_class": true,
               "icon": "ri-file-user-line",
            }
            this.sidebar.push(upload_domain);
          }else if(rights_list[key] == 'Assign_Publisher'){
            let assign_publisher = {
              "title": "Assign to Publisher",
              "name": "offers.pub",
              "is_active": false,
              "link": {
                "name" : "offers.pub"
              },
              "class_name": "",
              "is_icon_class": false,
              "icon": ""
            }
            this.sidebar.push(assign_publisher);
          }
          
        }
      }
    }else {
        this.$router.push({ name: 'auth1.login' })
      }
    },
    getManagerSidebar () {
      
    },
  },
  created () {
    this.getLocalStorage()
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>