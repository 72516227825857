<template>
  <div>
    <h1 class="mb-0">Reset Password</h1>
    <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
    <form class="mt-4" v-on:submit="submit">
      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" v-model="itmes.email" class="form-control mb-0"  placeholder="Enter email" required>
      </div>

      <div class="d-inline-block w-100">

        <button type="submit" class="btn btn-primary float-right">Reset Password</button>
      </div>

    </form>
  </div>
</template>
<script>
import usercontrol from '../../../Apis/usercontrol';
export default {
  name: 'RecoverPassword1',

  data() {
    return {
      itmes:{
      email: '',
      },
      successMessage: '',
      errorMessage: ''
    }
  },
  methods: {
    async submit (event) {
      event.preventDefault();
      try{
        this.successMessage = '';

        const response = await usercontrol.resetpassword(this.itmes);
        
        // Assuming your backend returns a success message
        if(this.successMessage = response.data.message){
        console.log('sent',this.successMessage);
        
        this.$router.push('/auth/confirm-mail1');
        }else{
            this.allerros = response.data.errors
          }
      } catch (error) {
        
      }
    
    }
  },

}
</script>
