<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Device</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add Device" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <b-form-group label="Device Name" label-for="name">
                  <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group label="Brand" label-for="code">
                  <b-form-input id="brand" type="text" placeholder="Brand Name" v-model="items.brand"></b-form-input>
                  <span v-if="allerros.brand" :class="['label label-danger']">@{{ allerros.brand[0] }}</span>
                </b-form-group>
                <b-form-group label="Model" label-for="model">
                  <b-form-input id="model" type="text" placeholder="Enter Model" v-model="items.model"></b-form-input>
                  <span v-if="allerros.model" :class="['label label-danger']">@{{ allerros.model[0] }}</span>
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="rows" 
              :fields="columns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              empty-text="No records found"
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" >
                  <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                </td>
              </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                    <span v-if="allerros.name && data.item.editable" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                  </template>
                  <template v-slot:cell(brand)="data">
                    <span v-if="!data.item.editable">{{ data.item.brand }}</span>
                    <input type="text" v-model="data.item.brand" v-else class="form-control">
                    <span v-if="allerros.brand && data.item.editable" :class="['label label-danger']">@{{ allerros.brand[0] }}</span>
                  </template>
                  <template v-slot:cell(model)="data">
                    <span v-if="!data.item.editable">{{ data.item.model }}</span>
                    <input type="text" v-model="data.item.model" v-else class="form-control">
                    <span v-if="allerros.model && data.item.editable" :class="['label label-danger']">@{{ allerros.model[0] }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="rows && rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Dictionary from '../../Apis/dictionary'
import Loader from '../../components/Loader.vue'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
    this.listDevice()
  },
  components: {
    Loader
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        name: '',
        brand: '',
        model: '',
        },
        editable: false,
        allerros:{}
      }
    },
    showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    edit(item) {
      if(this.editable_master == true){
          return false;
        }
        this.editable_master = true;
      item.editable = true
      this.allerros = {}  
        this.rows.forEach((element) => {
          if(element._id != item._id){
            element.editable = false
          }
        });
    },
    submit (item) {
      this.items = item;
      this.submitForm();
    },
    async submitForm(){
      this.loadingData = true;
      try{
        let response = await Dictionary.storeDevice(this.items)
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          if(this.editable_master == false){
              this.currentPage = 1;
            }
          this.listDevice()
          this.items = this.default()
          this.allerros={}
          this.$refs.myModalRef.hide();
          this.editable_master=false;
          this.loadingData = false;
        }else{
          console.log(response);
          this.allerros = response.data.errors
          this.loadingData = false;
        }
      }catch(error){
        this.loadingData = false;
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Device?')){
        try{
          let response = await Dictionary.deleteDevice(item._id)
          if(response.status == 200){
            this.listDevice()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        this.listDevice(); // Call listCountry when filter input is blurred
      },
    async listDevice(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
      try{
        let response = await Dictionary.listDevice(params)
        if(response.status == 200){
          let data = response.data.data.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
          });
          this.rows = data
          this.totalRows = response.data.data.total
          this.loadingData = false;
        }
      }catch(error){
        this.loadingData = false;
      }
      console.log(this.rows);
      this.loadingData = false;
    }
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listDevice',
  },
  data () {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Brand', key: 'brand', class: 'text-left', sortable: true },
        { label: 'Model', key: 'model', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      filters: {
    name: '',
    brand: '',
    model: '',
  },
      rows: {},
      items: {
        _id:'',
        name: '',
        brand: '',
        model: '',
      },
      allerros:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loadingData: true,
      editable_master:false
    }
  },
  created(){
    this.listDevice()
  },
  computed: {
    allFieldsValid() {
          return this.items.name && this.items.brand && this.items.model;
      },
  }
}
</script>
