<template>
    <b-modal ref="noteModalRef" id="note-modal" size="xl" :style="{ width: '1000px', height: '800px' }" hide-footer>
      <template #modal-title>
        {{ items.name }}
      </template>
      <b-container fluid>
        <b-row>
          <template>
            <form class="d-flex align-items-center" @submit.prevent="submitNote" action="javascript:void(0);">
              <textarea type="text" v-model="items.note" class="form-control mr-3" placeholder="Type your message" style="width: 900px;"/>
              <button type="submit"  class="btn btn-primary d-flex align-items-center p-2">
                <i class="fas fa-paper-plane" aria-hidden="true"></i>
                <span class="d-none d-lg-block ml-1">Send</span>
              </button>
            </form>
          </template>
          <template v-if="userNotes.length > 0"> 
            <TimeLine :items="displayedNotes" />
          </template>
        </b-row>
        <button v-if="showLoadMoreButton" @click="loadMore" class="btn btn-primary mt-3">Load More</button>
      </b-container>
    </b-modal>
  </template>
  
  <script>
  import Multiselect from 'vue-multiselect'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Offer from '../../Apis/offer'
  
  export default {
    name: 'NotePage',
    props: ["items"],
    data() {
      return {
        submittedNote: null,
        userNotes: [],
        displayedNotes: [],
        currentPage: 1,
        pageSize: 10,

        color:['success','primary','warning','info']
      };   
    },
    components: {
      Multiselect
    },
    mounted() {
      // Add event listener for modal shown event
      this.$refs.noteModalRef.$on('shown', this.handleModalShown);
    },
    methods: {
      close() {
        this.$emit('close');
      },
      async submitNote() {
        this.loadingData = true;
        try {
          const response = await Offer.storeNote(this.items);
          if (response.status === 201) {
            this.$toast.info('Successfully Note Created!', {
                    position: 'top-center'
                });
            this.items.note = '';
            await this.fetchUserNotes();
          } else {
            console.error('Failed to submit note:', response.statusText);
          }
        } catch(error) {
          console.error('Error submitting note:', error);
        } finally {
          this.loadingData = false;
        }
      },
      async fetchUserNotes() {
        try {
          const response = await Offer.listNotesByUserId(this.items);
          if (response.status === 200) {
            let data = response.data.data;
            this.userNotes = [];
            let colorIndex = 0;
            for(const key in data) {
       
          this.userNotes.push({
          color: this.color[colorIndex],
          title: data[key].username,
          right: data[key].created_at, 
          description: data[key].note,
          child: {
            type: 'img',
            items: []
          }
        });
        colorIndex = (colorIndex + 1) % this.color.length;
      }
            // Sort userNotes array in descending order
            this.userNotes.sort((a, b) => new Date(b.right) - new Date(a.right));
            this.loadMore();
            console.log('noteuser', this.userNotes);
          } else {
            console.error('Failed to fetch user notes:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching user notes:', error);
        }
      },
      handleModalShown() {
      },
      loadMore() {
        const endIndex = this.currentPage * this.pageSize;
      this.displayedNotes = this.userNotes.slice(0, endIndex);
      this.currentPage++;
        },
      show() {
        this.$refs.noteModalRef.show();
        this.fetchUserNotes();
      },
      hide() {
        this.$refs.noteModalRef.hide();
      }
    },
    computed: {
      showLoadMoreButton() {
      return this.displayedNotes.length < this.userNotes.length;
    },
    }
  };
  </script>
  
  <style>
  /* Set the width and height of the modal on large screens */
     @media (min-width: 1200px) {
    #note-modal .modal-dialog  {
      max-width: 1200px;
      max-height: 1200px;
    }
  } 
  </style>
  