<template>
  <div>
    <loader :isLoading="loadingData"/>
    <h1 class="mb-0">Sign Up</h1>
    <p>Enter your email address and password to access panel.</p>
    <form class="mt-4" v-on:submit="submit">
      <div class="form-group">
        <label for="name">Profile Name</label><span class="text-danger"> *</span>
        <input type="text" class="form-control mb-0" id="name" aria-describedby="emailHelp" v-model="items.username" placeholder="Your Profile Name">
        <span v-if="allerros.username" :class="['label label-danger']">@{{ allerros.username[0] }}</span>
      </div>
      <div class="form-group">
        <label for="email">Email address</label><span class="text-danger"> *</span>
        <input type="email" class="form-control mb-0" id="email" v-model="items.email" aria-describedby="emailHelp" placeholder="Enter email">
        <span v-if="allerros.email" :class="['label label-danger']">@{{ allerros.email[0] }}</span>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Password</label>
      <b-input-group>
                  <b-form-input
                  v-model="items.password"
                  type="password"
                  id="newpassword"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  placeholder="**********"
                />
                <b-input-group-append is-text @click="togglePassword('newpassword')">
                    <i v-show="showPassword == true" class="fa fa-eye" id="togglePassword" title="hide password"></i>
                    <i v-show="showPassword == false" class="fa fa-eye-slash" id="togglePassword" title="show password"></i>
                  </b-input-group-append>
                </b-input-group>
                <span v-if="allerros.password" :class="['label label-danger']">@{{ allerros.c_password[0] }}</span>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1"> Confirm Password</label>
      <b-input-group>
                  <b-form-input
                  v-model="items.c_password"
                  type="password"
                  id="c_password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  placeholder="**********"
                />
                <b-input-group-append is-text @click="togglePassword('c_password')">
                    <i v-show="showCPassowrd == true" class="fa fa-eye" id="togglePassword" title="hide password"></i>
                    <i v-show="showCPassowrd == false" class="fa fa-eye-slash" id="togglePassword" title="show password"></i>
                  </b-input-group-append>
                </b-input-group>
                <span v-if="allerros.c_password" :class="['label label-danger']">@{{ allerros.c_password[0] }}</span>
      </div>
      <label for="password">User Type</label><span class="text-danger"> *</span>
              <b-form-select plain v-model="items.user_type" placeholder="select User type" :options="Usertypes" size="lg">
                <!-- <template v-slot:first>
                  <b-form-select-option :value="null">User typre</b-form-select-option>
                </template> -->
              </b-form-select>
              <span v-if="allerros.user_type" :class="['label label-danger']">@{{ allerros.user_type[0] }}</span>
      <div class="d-inline-block w-100">
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" id="customCheck1" required>
          <label class="custom-control-label" for="customCheck1">I accept <a href="#">Terms and Conditions</a></label>
        </div>
        <button type="submit" class="btn btn-primary float-right">Sign Up</button>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">Already Have Account ? <a :href="linkUrl">Log In</a></span>
        <ul class="iq-social-media">
          <li><a href="#"><i class="ri-facebook-box-line"></i></a></li>
          <li><a href="#"><i class="ri-twitter-line"></i></a></li>
          <li><a href="#"><i class="ri-instagram-line"></i></a></li>
        </ul>
      </div>
    </form>
  </div>
</template>
<script>
import UserControl from '../../../Apis/usercontrol'
import Common from '../../../Apis/common'
import Loader from '../../../components/Loader'

export default {
  name: 'SignUp',
  components: {
    Loader
  },
  data () {
    return {
      linkUrl: '/auth/login',
      items:{
        username: '',
        email: '',
        password: '',
        c_password: '',
        user_type : '',
      },
      showPassword: false,
      showCPassowrd:false,
      loadingData : false,
      allerros:{},
      Usertypes:[],
    }
  },
  watch:{

  },
  methods: {
    async submit (event) {
      event.preventDefault();
      this.loadingData = true;
      try {
        let response = await UserControl.register(this.items)
        if(response.status == 201){
          this.$toast.info('Successfully Registration Is Done!', {
                    position: 'top-center'
                });
          console.log(response.message);
          this.$router.push('/auth/login')
        }else{
          this.allerros = response.data.errors;
        }
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
        console.log(e)
      }
    },
    async getUsertypes(){
      try {
        let response = await Common.userType()
        let responseData = response.data;
        const managerKey = Object.keys(responseData).find(key => responseData[key] === "Manager");
        // If the value "Manager" exists, unset it from the object.
        if (managerKey !== undefined) {
          delete responseData[managerKey];
        }
        for (const key in responseData) {
          this.Usertypes.push({ value: key, text: responseData[key] });
        }
      } catch (e) {
        console.log(e)
      }
    },
    togglePassword(id){
          const password = document.getElementById(id);
          if(id == 'newpassword')
          this.showPassword = this.showPassword === false ? true: false

          if(id == 'c_password')
          this.showCPassowrd = this.showCPassowrd === false ? true: false

          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        },
  },
  created () {
    this.getUsertypes()
  } 
}
</script>
