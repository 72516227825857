<template>
  <b-modal ref="groupOfferModal" id="modal-4" size="xl"  hide-footer>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Offers of {{ group_name }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModalOffer" variant="primary">Add New</b-button>
            <b-modal ref="myOfferGroup" id="modal-4" size="lg" title="Add Offer Group" hide-footer @hide="clearMessage">
              <b-form style="padding:2% 2% 2% 1%;" @submit.prevent="submitOfferForm">
              <b-form-group>
                <label for="usertype">Offer</label><span class="text-danger"> *</span>
                <multiselect
                    v-model="items.offer_id"
                    :options="offers"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group> 
              <b-form-group label="Weight" label-for="weight">
                  <b-form-input id="weight" type="number" placeholder="weight" v-model="items.weight"></b-form-input>
                  <span v-if="allerros.weight" :class="['label label-danger']">@{{ allerros.weight[0] }}</span>
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
                <div v-if="errorMessage" class="alert alert-danger mt-2">{{ errorMessage }}</div>

              </b-form>
            </b-modal>
          </template>
          <template>
            <div class="row">
        <div class="col-md-12">
          <b-row v-if="isManager || isAdmin ">
                  <b-col md="8">
                    <b-form-group label-for="Base URL">
                  <b-form-input id="base_url" type="text" placeholder="" v-model="hosts"></b-form-input>
                </b-form-group>
              </b-col>
                <b-col md="4">
                <multiselect
                    v-model="publisher"
                    :options="publishers"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="select publisher"
                    label="text"
                    track-by="value"
                    @input="linkGen"
                />
            </b-col>
            </b-row>
            <b-row>
            <b-col md="11">
          <b-form-group>
                  <b-form-textarea id="links" placeholder="tracking link" :disabled="true" type="text" v-model="links"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="1" v-if="links"><b-button variant=" iq-bg-success mr-1 mb-1" @click="copyText" size="xl"><i class="ri-file-copy-fill m-0" title="Copy"></i></b-button></b-col>  </b-row>
        </div>
      </div>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="group_offers" 
              :fields="columns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" >
                <template>
                  <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)"></b-form-input>
                </template>
                </td>
              </template>
                  <template v-slot:cell(name)="data">
                    <span>{{ data.item.name }}</span>
                  </template>
                  <template v-slot:cell(weight)="data">
                    <span v-if="!data.item.editable">{{ data.item.weight }}</span>
                    <input type="text" v-model="data.item.weight" v-else class="form-control">
                    <span v-if="allerros.weight && data.item.editable" :class="['label label-danger']">@{{ allerros.weight[0] }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="editData(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submitData(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1">
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</b-modal>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Dictionary from '../../Apis/dictionary'
import Offer from '../../Apis/offer'
import usercontrol from '../../Apis/usercontrol'
import Multiselect from 'vue-multiselect'


export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.listPublisher()
  },
  components: {
    Multiselect
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        name: '',
       status: '',
        },
        editable: false,
      }
    },
    async copyText(){
      const url = this.links;
      try {
        if (navigator.clipboard) {
          // Use navigator.clipboard API if available
          await navigator.clipboard.writeText(url);
        } else {
          // Use document.execCommand() fallback for older browsers
          const textarea = document.createElement('textarea');
          textarea.value = url;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
        }
        this.$toast.info('Promotion URL copied!', {
          position: 'top-center'
        });
      } catch (err) {
        console.error('Failed to copy URL to clipboard: ', err);
      }
    },
    showModalOffer(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myOfferGroup.show();
    },
    editData(item) {
      if(this.editable_master == true){
          return false;
        }
      this.editable_master = true;
      item.editable = true;
      this.allerros = {}  
      this.group_offers.forEach((element) => {
        if(element._id != item._id){
          element.editable = false
        }
      });
    },
    submitData(item) {
      this.items = item;
      this.submitOfferForm();
    },
    checkValue(value){
      if(value.value){
        return value.value
      }else{
        return value
      }
    },
    async submitOfferForm(){
      this.items.group_id = this.group_id
      this.items.offer_id = this.checkValue(this.items.offer_id);
      try{

        let response = await Offer.storeOfferGroupOffer(this.items)
        this.errorMessage = '';
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          this.listOfferGroupOffer(this.group_id)
          this.items = this.default()
          this.$refs.myOfferGroup.hide();
          this.editable_master=false;
        }else{
          this.errorMessage = response.data.error;
        }
      }catch(error){
        this.errorMessage = 'The combination of offer and weight already exists.';
      }
    },
    clearMessage() {
      this.errorMessage = '';
    },
    async listPublisher(){
      try{
        let response = await usercontrol.listPublisher()
        this.hosts = process.env.VUE_APP_BASE_URL;
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.publishers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
      }catch(error){
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Offer?')){
        try{
          let response = await Offer.deleteOfferGroupOffer(item._id)
          if(response.status == 200){
            this.listOfferGroupOffer(this.group_id)
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    async listOfferGroupOffer(group_id){
      try{
        let response = await Offer.listOfferGroupOffer(group_id)
        if(response.status == 200){
          let data = response.data.data
          data.forEach((element) => {
            element.editable = false
          });
          this.group_offers = data
        }
      }catch(error){
      }
    },
    close() {
        this.$emit('close');
      },
      show() {
      this.$refs.groupOfferModal.show();
      },
      hide() {
        this.$refs.groupOfferModal.hide();
      },
      linkGen(){
        this.links = this.hosts+"site/group_click?group_id="+this.group_id+"&user_id="+this.publisher.value;
      }
  },
  data () {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Weight', key: 'weight', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      filters: {
        offer: '',
        // weight: '',
      },
      items: {
        _id:'',
        offer_id: '',
        group_id:'',
        weight: '',
      },
      allerros:{},
      totalRows:0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      editable_master:false,
      filterOn: [],
      publishers:[],
      errorMessage: '',
      hosts: '',
      publisher:'',
    }
  },
  created(){
    // this.loadData();
  },
  props: ["group_id","group_name","group_offers","offers","links","isPublisher","isAdmin","isManager"],
  computed: {
    allFieldsValid() {
      return this.items.offer_id && this.items.weight;
   },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>
