<template>
    <b-modal ref="offerDetails" id="offer-details-modal" size="xl" :style="{ width: '90%' }" hide-footer>
      <b-container fluid>
        <b-row>
        <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
             <img :src="items.image" alt="Offer Icon" width="100" height="100" class="mr-2">
             {{ items.int_id }} - {{ items.name }}
          </template>
          <template v-slot:headerAction>
            <b-button  @click="showModalOffer" variant="primary">Upload Link</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="xl" :title="items.name" hide-footer >
              <b-form  class="mt-4" @submit.prevent="submitOfferForm">
                <b-form-row>
                      <b-col>
                          <b-form-radio v-model="type" value="link" checked>Link</b-form-radio>
                      </b-col>
                      <b-col>
                           <b-form-radio v-model="type" value="image">Image</b-form-radio>
                      </b-col>
                </b-form-row>

                <b-form-group v-if="type === 'link'" style="margin-top: 10px;">
                          <b-form-input v-model="linkInput" placeholder="Enter link"></b-form-input>
                 </b-form-group>

                 <b-form-group v-if="type === 'image'" style="margin-top: 10px;">
                   <b-form-file
                   v-model="files"
                   placeholder="Choose image files"
                    id="customFile"
                    @change="onFileChange($event)"
                   multiple
                  ></b-form-file>
                 </b-form-group>
                
                <b-button type="submit" variant="primary" style="margin-top: 20px;" >Submit</b-button>
              </b-form>
              
              <template>
                <div>
                  <div v-if="type === 'image' && allImages.length > 0" style="margin-top: 20px;">
                   <h5>Images:</h5>
                      <div class="images-grid">
                           <div v-for="(img, index) in allImages" :key="index" class="image-container">
                              <a :href="img_url + '/storage/' + img" target="_blank">
                                 <img :src="img_url + '/storage/' + img" alt="Offer Icon" width="250" height="250" class="mr-2"><br><br>
                              </a>
                              <b-button variant="iq-bg-danger" class="btn-xxl remove-button" @click="removeImage(index)">
                                  <i class="ri-delete-bin-line m-0"></i>
                              </b-button>
                           </div>
                       </div>
                   </div>
                </div>  
              </template>
            </b-modal>
          </template>
      <div class="row" style="margin-top: 10px;">
        <div class="col-md-12">
          <b-row v-if="isManager || isAdmin || (isAdvertiser && isRights && this.isRights.includes('Link_Generate'))">
                  <b-col md="8">
                    <b-form-group label-for="Base URL">
                  <b-form-input id="base_url" type="text" placeholder="" v-model="items.hosts" ></b-form-input>
                </b-form-group>
              </b-col>
                <b-col md="4">
                <multiselect
                    v-model="publisher"
                    :options="publishers"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="select publisher"
                    label="text"
                    track-by="value"
                    @input="linkGen"
                />
            </b-col>
            </b-row>
            <b-row v-if="(isAdvertiser && isRights && this.isRights.includes('Link_Generate')) || ( isManager || isAdmin || isPublisher )">
            <b-col md="11">
          <b-form-group>
                  <b-form-textarea id="promolink" placeholder="tracking link" :disabled="true" type="text" v-model="promolink"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="1" v-if="promolink"><b-button variant=" iq-bg-success mr-1 mb-1" @click="copyText" size="xl"><i class="ri-file-copy-fill m-0" title="Copy"></i></b-button></b-col>
          <b-col md="12" v-if="promolink">extra parameters</b-col>
          <template v-if="promolink">
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub1" placeholder="sub1" type="text" @input="handleBlur('sub1')" class="light-placeholder" v-model="sub1" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub2" type="text" placeholder="sub2" @input="handleBlur('sub2')" class="light-placeholder" v-model="sub2" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub3" type="text" placeholder="sub3" @input="handleBlur('sub3')" class="light-placeholder" v-model="sub3" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub4" type="text" placeholder="sub4" @input="handleBlur('sub4')" class="light-placeholder" v-model="sub4" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="12"><p style="float: right;cursor: pointer;" @click="openOther" v-if="showMore == false">show more</p></b-col>
            </template>
            </b-row>
            <b-row v-if="showMore">
              <b-col md="3">
              <b-form-group>
            <b-form-input id="sub5" placeholder="sub5" type="text" @input="handleBlur('sub5')" class="light-placeholder" v-model="sub5" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub6" type="text" placeholder="sub6" @input="handleBlur('sub6')" class="light-placeholder" v-model="sub6" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub7" type="text" placeholder="sub7" @input="handleBlur('sub7')" class="light-placeholder" v-model="sub7" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub8" type="text" placeholder="sub8" @input="handleBlur('sub8')" class="light-placeholder" v-model="sub8" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub9" placeholder="sub9" type="text" @input="handleBlur('sub9')" class="light-placeholder" v-model="sub9" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub10" type="text" placeholder="sub10" @input="handleBlur('sub10')" class="light-placeholder" v-model="sub10" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub11" type="text" placeholder="sub11" @input="handleBlur('sub11')" class="light-placeholder" v-model="sub11" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
            <b-form-input id="sub12" type="text" placeholder="sub12" @input="handleBlur('sub12')" class="light-placeholder" v-model="sub12" ></b-form-input>
            </b-form-group>
            </b-col>
            <b-col md="12"><p style="float: right;cursor: pointer;" @click="openOther" v-if="showMore == true">show less</p></b-col>
          </b-row>

          <b-form-group>
            <div style="padding: 15px; margin: 5px 0; border: 1px solid #ccc;  border-radius: 4px;">
              <template>
                <div>
                      <div v-if="type === 'link' && this.type != null" style="margin-top: 10px;">
                            <a :href="PrevirelinkData" target="_blank"  v-if="localPreviewLink">{{ localPreviewLink }}</a>
                      </div>

                   <div v-else-if="type === 'image' && allImages.length > 0" style="margin-top: 10px;">
                          <a :href="localPreviewLink" target="_blank"  v-if="localPreviewLink">{{ localPreviewLink }}</a>
                  </div>
                </div>  
              </template>
            </div>
          </b-form-group>
          
          <p><strong>Description:</strong> {{ items.description }}</p>
          <p><strong>Price:</strong> {{ items.price }}</p>
          <p><strong>Pricing Model:</strong> {{ pricingModelText }}</p>
          <p><strong>Device Type:</strong> {{ deviceTypeText }}</p>
          <p><strong>Expire Date:</strong> {{ items.expire_date }}</p>
          <p><strong>Type:</strong> {{ offerTypeText }}</p>
          <p><strong>Note:</strong> {{ items.note }}</p>

           <h4>Offer Targeting</h4>

<div v-if="offer_targetdata && 
           offer_targetdata.country && Array.isArray(offer_targetdata.country) && offer_targetdata.country.length > 0 &&
           offer_targetdata.os && Array.isArray(offer_targetdata.os) && offer_targetdata.os.length > 0 &&
           offer_targetdata.carrier && Array.isArray(offer_targetdata.carrier) && offer_targetdata.carrier.length > 0 &&
           offer_targetdata.country_status != null &&
           offer_targetdata.os_status != null &&
           offer_targetdata.carrier_status != null">

       <p><strong>Country:</strong> {{ offer_targetdata.country.join(', ') }}</p>
       <p><strong>OS:</strong> {{ offer_targetdata.os.join(', ') }}</p>
       <p><strong>Carrier:</strong> {{ offer_targetdata.carrier.join(', ') }}</p>
       <p><strong>Country_status:</strong> 
        {{ offer_targetdata.country_status == 101 ? 'Include' : (offer_targetdata.country_status == 102 ? 'Exclude' : '') }}</p>
       <p><strong>Os_status:</strong> 
      {{ offer_targetdata.os_status == 101 ? 'Include' : (offer_targetdata.os_status == 102 ? 'Exclude' : '') }}</p>
        <p><strong>Carrier_status:</strong> 
    {{ offer_targetdata.carrier_status == 101 ? 'Include' : (offer_targetdata.carrier_status == 102 ? 'Exclude' : '') }}</p>

</div>
        </div>
      </div>
    </iq-card>
      </b-col>
    </b-row>
  </b-container>
    </b-modal>
</template>
<script>
import Multiselect from 'vue-multiselect'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Offer from '../../Apis/offer'

  export default {
    name: 'Modal',
   data : function(){
    return{
      allImages:[],
      macros:['sub1','sub2', 'sub3','sub4','sub5', 'sub6','sub7','sub8', 'sub9','sub10','sub11', 'sub12','clickid','subid'],
      showMacro:false,
      publisher:'',
      showMore:false,
      promolink:'',
      sub1:'',
      sub2:'',
      sub3:'',
      sub4:'',
      sub5:'',
      sub6:'',
      sub7:'',
      sub8:'',
      sub9:'',
      sub10:'',
      sub11:'',
      sub12:'',
      subs: [],
      type: 'link', 
      linkInput: '',        
      files: [],
      localPreviewLink: '',
      PrevirelinkData: '',
      data: [],
      img_url:'',
    }
   },
   components: {
    Multiselect
  },
  watch:{

      links(){this.promolink = this.links},
    data(){
      if(this.type == 'link'){
          this.type = 'link';
      }else{
          this.type = 'image'
      }
     this.allImages = this.data.map(item => item.images).flat();
    }

  },
   mounted() {
    this.img_url = process.env.VUE_APP_BASE_URL;
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.close() 
      }
    })
  },
    methods: {
      close() {
        this.$emit('close');
      },
      async copyText(){
        const url = this.promolink;
        try {
          if (navigator.clipboard) {
            // Use navigator.clipboard API if available
            await navigator.clipboard.writeText(url);
          } else {
            // Use document.execCommand() fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = url;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
          }
          this.$toast.info('Promotion URL copied!', {
            position: 'top-center'
          });
        } catch (err) {
          console.error('Failed to copy URL to clipboard: ', err);
        }
      },
      openOther(){
        this.showMore = !this.showMore;
      },

      showModalOffer(){
      this.$refs.myModalRef.show();
      this.getOfferDetails();
      },

      async submitOfferForm(){
      try{
      const formData = new FormData()
      formData.append('offer_id', this.items._id);
      formData.append('link', this.linkInput);
      formData.append('type', this.type);

      if (this.type === 'image') {
      const files = document.querySelector('input[type="file"]').files;

      if (files.length > 0) {
        // Append each file to the FormData object
        for (const file of files) {
          formData.append('images[]', file);
        }
      } 
    }
    
        let response = await Offer.storeImageOffer(formData)
        this.localPreviewLink = `${this.items.hosts}site/preview_link?offer_id=${this.items._id}`; 
         this.PrevirelinkData = this.linkInput,
        this.linkInput= '',        
        this.files = [],
        this.getOfferDetails();
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });     
          this.$refs.myModalRef.hide();      
        }else{
          this.errorMessage = response.data.error;
        }
      }catch(error){
      }
    },

   async removeImage(index){
    if(confirm('Are you sure to delete this Image?')){
      try{
      const imageName = this.allImages[index];
      let response = await Offer.removeImages({imageName});
      if(response.status == 200){
        const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
            this.getOfferDetails()
          }else{
            this.allerros = response.data.errors
          }
      }catch(error){
    }
   }
 },

    async getOfferDetails(){
      try{
        let id = this.items._id;
        let response = await Offer.getData({id});
        if(response.status == 201){
          this.data = response.data.data
        }
      }catch(error){
      }
      
    },
        handleBlur(queryName) {
          const queryValue = this[queryName];
          if (queryValue !== undefined && queryValue !== null && this.links != '') {
            let PromotionLink = this.links;

             // Find the index of the existing key-value pair
             const index = this.subs.findIndex(pair => pair.key === queryName);

              if (index !== -1) {
                this.subs.splice(index, 1);
               }
             this.subs.push({ key: queryName, value: queryValue });
             let str = '';
             this.subs.forEach(pair => {
             if (pair.value !== undefined && pair.value !== null && pair.value != '') {
             // Check if the value is enclosed in curly braces
                 if (pair.value.startsWith('{') && pair.value.endsWith('}')) {
                 str += `${pair.key}=${pair.value}&`;
                 } else {
                 str += `${pair.key}=${pair.value}&`;
                 }
                }
              });
            str = str.slice(0, -1);
            this.promolink = PromotionLink + '&' + str;
           }
      },
      addMacros(data){
        const index = this.macros.indexOf(data);
        var str = "{" + data + "}";
        if(this.items.url == null){
          this.items.url = str;
          if (index > -1) {
            this.macros.splice(index, 1);
          }
        }else{
          if(!this.items.url.match(new RegExp(str))){    
              this.items.url = this.items.url + str;
              if (index > -1) {
                this.macros.splice(index, 1);
              }
          } 
        }
        },
        onFileChange(event) {
            this.files = event.target.files[0];
        },
        getImageUrl(imageName) {
      // Replace this with your logic to get the correct URL for the image
      return `/path/to/images/${imageName}`;
    },
        showMacros(){
          this.showMacro = !this.showMacro;
        },
      show() {
        this.publisher = '';
        this.localPreviewLink = '';
        this.$refs.offerDetails.show();
      },
      hide() {
        this.$refs.offerDetails.hide();
      },
      linkGen(){
        this.links = this.items.hosts+"site/click?offer_id="+this.items._id+"&user_id="+this.publisher.value+"&ref="+this.items.int_id;
      }
    },
     props: ["items","links","allerros","deviceType","active_status","convFlow","pricingModels","isManager","isAdmin","isAdvertiser","isPublisher","isRights","offerType","publishers","login_id", "offer_targetdata"],
     computed:{
      pricingModelText() {
        const pricingModel = this.pricingModels.find(model => model.value === this.items.pricing_model);
        return pricingModel ? pricingModel.text : '';
      },
      deviceTypeText(){
        const device = this.deviceType.find(model => model.value === this.items.device_type);
        return device ? device.text:'';
      },
      offerTypeText(){
        const offer_type = this.offerType.find(model => model.value === this.items.type);
        return offer_type ? offer_type.text:'';
      },
    }
      
  };
</script>
<style scoped>
.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust column size */
  gap: 20px; /* Adds space between images */
  justify-items: center; /* Center the items horizontally */
  margin-top: 20px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remove-button {
  margin-top: 10px;
}
</style>