<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Dummy Postback Logs</h4>
          </template>
          <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <b-row>
            <b-col md="7">
                  <label for="date_range">Offers</label>
                <multiselect
                    v-model="items.offer"
                    :options="offers"
                    :multiple="false"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
            </b-col>
            </b-row>
            <br/>
            <b-button type="submit" variant="primary">Submit</b-button>
              </form>
              <br/>
              <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                  <div v-if="rows.length > 0">
                <div style="color: red;">Note: Click on Response or URL to copy</div>
              </div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
    </b-col>
    <b-table bordered hover 
              :items="rows" 
              :fields="columns"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <!-- Publisher Column -->
    <template v-slot:cell(user)="data">
      <div class="text-left" :title="data.item.user">
        {{ truncateText(data.item.user) }}
      </div>
    </template>

    <!-- Offer Column -->
    <template v-slot:cell(offer)="data">
      <div class="text-left" :title="data.item.offer">
        {{ truncateText(data.item.offer) }}
      </div>
    </template>

    <template v-slot:cell(clickid)="data">
      <div class="text-left" :title="data.item.clickid">
        {{ truncateText(data.item.clickid) }}
      </div>
    </template>

    <!-- Response Column -->
    <template v-slot:cell(response)="data">
      <div class="text-left" :title="data.item.response" @click="copyText(data.item.response)">
        {{ truncateText(data.item.response) }}
      </div>
    </template>

    <!-- URL Column -->
    <template v-slot:cell(url)="data">
      <div class="text-left" :title="data.item.url" @click="copyText(data.item.url)">
        {{ truncateText(data.item.url) }}
      </div>
    </template> 
    <template v-slot:cell(postback)="data">
                    <div class="text-left">
                      <b-button v-if="!data.item.response"  variant="primary" type="button" @click="handlePostBack(data.item)">
                        PostBack
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="rows.length > 0">
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
  import { sofbox } from '../../config/pluginInit'
  import Common from '../../Apis/common'
  import UserControl from '../../Apis/usercontrol'
  import Offer from '../../Apis/offer'
  import DateRangePicker from 'vue2-daterange-picker'
  import Multiselect from 'vue-multiselect'
  //you need to import the CSS manually
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Loader from '../../components/Loader.vue'
  export default {
    name: 'UiDataTable',
    mounted () {
      sofbox.index(),
      this.listOffers(),
      this.getUser()
      //this.handlePostBack()
    },
    components: {
      DateRangePicker,Multiselect,Loader
    },
    data () {
      return {
        columns: [
          // { label: 'Publisher', key: 'user', class: 'text-left', sortable: true },
          // { label: 'Offer', key: 'offer', class: 'text-left', sortable: true },
          { label: 'Response', key: 'response', class: 'text-left', sortable: true },
          { label: 'URL', key: 'url', class: 'text-left',sortable:true},
          { label: 'ClickId', key: 'clickid', class: 'text-left',sortable:true},
          { label: 'PostBack', key: 'postback',class: 'text-left'}
  
        ],
        dateRange:{
          startDate: new Date(),
          endDate: new Date()
        },
        items: {
          user:[],
          offer:[]
        },
        users:[],
        offers:[],
        rows: [],
        opens: 'right',
        localeData:{
            direction: 'ltr',
            format: 'yyyy/mm/dd',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            firstDay: 0
        },
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10,20,50,100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        loadingData:true,
        login_id:'',
      }
    },
    methods: {
      formatDateToYMD(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      checkValue(value){
        if(value == null || value == undefined || value == ''){
          return ''
        }else{
          return value
        }
      },
      truncateText(text, maxLength = 20) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      },
      async copyText(mytext) {
        try {
          await navigator.clipboard.writeText(mytext);
          this.$toast.info('Copied Clicked One!', {
                      position: 'top-center'
                  })
        } catch($e) {
        }
      },
      async handlePostBack(item){
        this.loadingData = true;
        try { 
        let response = await Common.updateDummyPostback(item)
        if(response.status == 200){
          const message = response.data.message;
            this.$toast.info(message, {
                      position: 'top-center'
                  });
        }
        this.loadingData = false;
          this.submitForm()
      }catch(error){
        this.loadingData = false;
      }
      },
      async submitForm(){
        this.loadingData = true;
        var id;
          const data = {
            sdate: this.formatDateToYMD(this.dateRange.startDate),
            edate:this.formatDateToYMD(this.dateRange.endDate),
            id : this.login_id,
            offer: this.items.offer.value
          };
        try{
          let response = await Common.getDummyPostbackData(data)
          if(response.status == 200){
            this.rows = response.data.data;
            console.log('thirows',this.rows);
          }else{
            this.allerros = response.data.errors
          }
          this.loadingData = false;
        }catch(error){
          this.loadingData = false;
        }
      },
      async listUsers(){
        this.loadingData = true;
        try{
          let response = await UserControl.listPublisher()
          if(response.status == 200){
            let data = response.data.data
            console.log(data);
            for(const key in data){
              this.users.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
            }
          }
          this.loadingData = false;
        }catch(error){
          this.loadingData = false;
        }
      },
      async listOffers(){
        this.loadingData = true;
        try{
          let response = await Offer.listActiveOffer()
          if(response.status == 200){
            let data = response.data.data
            console.log('offfff',data);
            console.log(data);
            for(const key in data){
              this.offers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].name})
            }
          }
          this.loadingData = false;
        }catch(error){
          this.loadingData = false;
        }
      },
      getUser(){
      const user = localStorage.getItem('user')
      if (user) {
        let role = JSON.parse(user)
        this.login_id= role._id
        
      }
    },
    },
    watch: {
      // Watch for changes in currentPage and fetchData accordingly
       currentPage: 'submitForm',
    },
    computed: {
    }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style>
  .filter:focus-within .multiselect__content-wrapper {
      width: 300px!important;
  }
  </style>