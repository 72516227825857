<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <loader :isLoading="loadingData" />
          <template>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-3 p-0" :active="true" href="#personal-information" title="Personal Information" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#chang-pwd" title=" Change Password" />
                <tab-nav-items class="col-md-2 p-0" :active="false" v-if="role == 'publisher'" href="#emailandsms" title="PostBack" />
                <tab-nav-items class="col-md-2 p-0" :active="false" v-if="(isAdmin && this.usersType ==1 && this.$route.query.id != null) || (this.usersType ==1 && this.$route.query.id != null && this.isRights.includes('User_Share'))"  href="#user_share" title="User Share" />
                <tab-nav-items class="col-md-2 p-0" :active="false" v-if="role != 'publisher'" href="#manage-contact" title="Token" />
                 <tab-nav-items class="col-md-2 p-0" :active="false" v-if="this.$route.query.id != null && isAdmin"  href="#rights" title="Rights" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <tab-content id="pills-tabContent-2">
            <tab-content-item :active="true" id="personal-information" >
              <iq-card>
                <template v-slot:headerAction>
                  <b-form class="mt-2" @submit.prevent="storeProfile">
                    <b-row align-v="center">
                      <b-form-group class="col-sm-6"
                        label="First Name:"
                        label-for="fname"
                      >
                        <b-form-input id="fname" v-model="profile.firstname" placeholder="FirstName"></b-form-input>
                        <span v-if="p_allerrors.firstname" :class="['label label-danger']">@{{ p_allerrors.firstname[0] }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-6"
                        label="Last Name:"
                        label-for="lname"
                      >
                        <b-form-input id="lname" v-model="profile.lastname" placeholder="LastName"></b-form-input>
                        <span v-if="p_allerrors.lastname" :class="['label label-danger']">@{{ p_allerrors.lastname[0] }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-6"
                        label="Profile Name:"
                        label-for="uname"
                      >
                      <b-form-input id="uname" :readonly="true" v-model="user_data.username"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-sm-6"
                        label="Email:"
                        label-for="email"
                      >
                      <b-form-input id="email" :readonly="true" v-model="user_data.email"></b-form-input>
                      </b-form-group>
                       <b-form-group class="col-sm-6"
                        label="City :"
                        label-for="city"
                      >
                        <b-form-input type="text" v-model="profile.city"></b-form-input>
                        <span v-if="p_allerrors.city" :class="['label label-danger']">@{{ p_allerrors.city[0] }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-6"
                        label="State :"
                        label-for="state"
                      >
                        <b-form-input type="text" v-model="profile.state"></b-form-input>
                        <span v-if="p_allerrors.state" :class="['label label-danger']">@{{ p_allerrors.state[0] }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-6"
                        label="Country :"
                        label-for="Country"
                      >
                        <b-form-input type="text" v-model="profile.country"></b-form-input>
                        <span v-if="p_allerrors.country" :class="['label label-danger']">@{{ p_allerrors.country[0] }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-6"
                        label="Postal Code :"
                        label-for="Postal">
                      <b-form-input type="text" v-model="profile.postal_code"></b-form-input>
                      <span v-if="p_allerrors.postal_code" :class="['label label-danger']">@{{ p_allerrors.postal_code[0] }}</span>
                      </b-form-group>
                      <b-form-group class="col-sm-12"
                        label="Address:">
                        <b-form-textarea name="address" style="line-height: 22px;" v-model="profile.address" rows="5" value="">
                          <span v-if="p_allerrors.address" :class="['label label-danger']">@{{ p_allerrors.address[0] }}</span>
                        </b-form-textarea>
                      </b-form-group>
                    </b-row>
                    <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                  </b-form>
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="chang-pwd">
              <iq-card>
                <template>
                  <b-form style="padding:2% 2% 2% 1%;" @submit.prevent="storePassword">
                    <b-row align-v="center" class="col-lg-12" v-if="this.$route.query.id == null">
                      <b-form-group class="col-sm-10">
                      <label for="cpass">Current Password:</label>
                      <b-form-input type="text" v-model="password.old_password"></b-form-input>
                      <span v-if="allerrors.old_password" :class="['label label-danger']">@{{ allerrors.old_password[0] }}</span>
                    </b-form-group>
                    <b-form-group class="col-sm-10">
                      <label for="npass">New Password:</label>
                      <b-form-input type="text" v-model="password.new_password"></b-form-input>
                      <span v-if="allerrors.new_password" :class="['label label-danger']">@{{ allerrors.new_password[0] }}</span>
                    </b-form-group>
                    <b-form-group class="col-sm-10">
                      <label for="vpass">Verify Password:</label>
                      <b-form-input type="text" v-model="password.conf_password"></b-form-input>
                      <span v-if="allerrors.conf_password" :class="['label label-danger']">@{{ allerrors.conf_password[0] }}</span>
                    </b-form-group>
                  </b-row>
                  <b-row align-v="center" class="col-lg-12" v-else>
                    <b-form-group class="col-sm-10">
                      <label for="npass">New Password:</label>
                      <b-form-input type="text" v-model="password_change.new_password"></b-form-input>
                      <span v-if="allerrors.new_password" :class="['label label-danger']">@{{ allerrors.new_password[0] }}</span>
                    </b-form-group>
                    <b-form-group class="col-sm-10">
                      <label for="vpass">Verify Password:</label>
                      <b-form-input type="text" v-model="password_change.conf_password"></b-form-input>
                      <span v-if="allerrors.conf_password" :class="['label label-danger']">@{{ allerrors.conf_password[0] }}</span>
                    </b-form-group>
                  </b-row>
                    <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                  </b-form>
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="emailandsms">
              <iq-card>
                <template>
                  <b-form style="padding:2% 2% 2% 1%;" @submit.prevent="storePostBack">
                    <b-form-group>
                      <label for="url">Url</label><span class="text-danger"> *</span>
                      <b-form-textarea id="url" rows="4" placeholder="Url" v-model="pb.url"></b-form-textarea>
                    </b-form-group>
                    <b-form-group>
                <label for="usertype">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="pb.status" :options="user_status" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
                    <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                  </b-form>
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="user_share">
              <iq-card>
                <template>
                  <b-form style="padding:2% 2% 2% 1%;" @submit.prevent="storeUserShare">
                    <b-form-group >
                <label for="usertype">User Share Type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="share_type.share_type" :options="userShareType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">User Share Type</b-form-select-option>
                        </template>
              </b-form-select>
                <label for="rev_share">Revenue share</label><span class="text-danger"> *</span>
                <input type="text" class="form-control mb-0" id="rev_share" v-model="share_type.rev_share" aria-describedby="reveShare" placeholder="Revenue share">
                <span v-if="allerros.rev_share" :class="['label label-danger']">@{{ allerros.rev_share[0] }}</span>
            </b-form-group>
                    <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                  </b-form>
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="manage-contact">
              <iq-card>
                <template>
                  <b-form style="padding:2% 2% 2% 1%;" @submit.prevent="storeToken">
                    <b-form-group>
                      <label>User Token:</label>
                      <b-form-input type="text" v-model="token.token"></b-form-input>
                    </b-form-group>
                    <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                  </b-form>
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="rights">
              <iq-card>
                <template>
                  <b-form style="padding:2% 2% 2% 1%;" @submit.prevent="storeRights">
                    <b-form-group v-if="role == 'manager'">
                <label for="rights">Rights</label><span class="text-danger"> *</span>
                 <multiselect
                   v-model="rights.rights" 
                   :options="managerRights"
                   :multiple="true"
                   :close-on-select="false"
                   :clear-on-select="false"
                   :preserve-search="true"
                   placeholder="Pick some"
                   lablabel="text"
                      >
                  </multiselect>
                     </b-form-group>
                     <b-form-group v-else-if="role == 'publisher'">
                <label for="rights">Rights</label><span class="text-danger"> *</span>
                 <multiselect
                   v-model="rights.rights" 
                   :options="publisherRights"
                   :multiple="true"
                   :close-on-select="false"
                   :clear-on-select="false"
                   :preserve-search="true"
                   placeholder="Pick some"
                   lablabel="text"
                      >
                  </multiselect>
                     </b-form-group>
                     <b-form-group v-else>
                <label for="rights">Rights</label><span class="text-danger"> *</span>
                 <multiselect
                   v-model="rights.rights" 
                   :options="userRights"
                   :multiple="true"
                   :close-on-select="false"
                   :clear-on-select="false"
                   :preserve-search="true"
                   placeholder="Pick some"
                   lablabel="text"
                      >
                  </multiselect>
                     </b-form-group>
                    <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
                  </b-form>
                </template>
              </iq-card>
            </tab-content-item>
          </tab-content>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import usercontrol from '../../Apis/usercontrol'
import Common from '../../Apis/common'
import Loader from '../../components/Loader.vue'
import Multiselect from 'vue-multiselect'
export default {
  name: 'ProfileEdit',
  mounted () {
    sofbox.index()
    this.loadData()
  },
  components: {
    Loader,Multiselect
  },
  data () {
    return {
      profile:{
        firstname:'',
        lastname:'',
        city:'',
        state:'',
        address:'',
        country:'',
        postal_code:'',
      },
      password:{
        old_password:'',
        new_password:'',
        conf_password:'',
      },
      password_change:{
        new_password:'',
        conf_password:'',
        user_id: '',
      },
      pb:{
        user_id:'',
        url:'',
        status:'',
      },
      token:{
        token:'',
        user_id:'',
      },
      share_type:{
        share_type:'',
        rev_share: '',
        user_id:'',
      },
      rights:{
        user_id:'',
        rights: ''
      },
      login_id:'',
      user_data:'',
      isPublisher:false,
      isAdvertiser:false,
      isManager:false,
      isAdmin:false,
      isRights:'',
      role:'',
      rights_user:'',
      usersType: '',
      user_status:[],
      loadingData:false,
      allerrors:{},
      p_allerrors:{},
      userShareType:[],
      allerros:{},
      userRights: [],
      managerRights: [],
      publisherRights: []
    }
  },
  methods: {
    async loadData(){
      this.loadingData = true;
      try { 
          await this.setRole(),
          await this.fetchUser(),
          await this.getUserStatus()
        } catch (error) {
        console.error('Error loading data:', error);
      }
      this.loadingData = false;
    },
    async fetchUser(){
      var id = this.$route.query.id;
        if(id == undefined){
          id = this.login_id
        }
        this.pb.user_id = id
        this.profile.user_id = id
        this.token.user_id = id
        this.share_type.user_id = id
        this.rights.user_id = id
      try{
        let response = await usercontrol.getUserDetails(id);
        if(response.status == 200){
          if(response.data.profile != null){
            this.profile = response.data.profile;
          }
          this.usersType = response.data.user.user_type;
          this.user_data = response.data.user;
          this.token = response.data.user;
          this.role = response.data.role;
          this.rights.rights = response.data.user.rights;
          this.rights_user = response.data.user.rights;
          this.share_type = {
                ...this.share_type,
                  share_type: response.data.user.share_type, 
                 rev_share: response.data.user.rev_share 
          };

          if(response.data.postback != null){
            this.pb = response.data.postback;
          }
        }
      }catch(error){
      }
    },
    async storeProfile(){
      this.loadingData = true;
      try{
        let response = await usercontrol.updateProfile(this.profile);
        if(response.status == 200){
          this.$toast.info('Profile Updated!', {
                    position: 'top-center'
                })
        }
        if(response.status == 422){
        this.p_allerrors = response.data.errors;
        }
      }catch(error){
        this.$toast.info('Getting Error !', {
                    position: 'top-center',
                    class:'danger'
                })
      }
      this.loadingData = false;
    },
    async storeUserShare(){
      this.loadingData = true;
      try{
        let response = await usercontrol.updateUserShare(this.share_type);
        if(response.status == 200){
          this.$toast.info('User Share Updated!', {
                    position: 'top-center'
                })
        }
        if(response.status == 422){
        this.p_allerrors = response.data.errors;
        }
      }catch(error){
        this.$toast.info('Getting Error !', {
                    position: 'top-center',
                    class:'danger'
                })
      }
      this.loadingData = false;
    },
    async storePassword(){
      this.loadingData = true;
      try{
         let response;
        if(this.$route.query.id == null)
        {
           response = await usercontrol.updatePassword(this.password);
        }else{
          this.password_change.user_id = this.$route.query.id
           response = await usercontrol.updatePassword(this.password_change);
        }
        if(response.status == 200){
          this.$toast.info('Password Updated!', {
                    position: 'top-center'
                });
          this.password.old_password = '';
          this.password.new_password = '';
          this.password.conf_password = '';
        }
        if(response.status == 422){
        this.allerrors = response.data.errors;
        }
        if(response.status == 400){
          const message = response.data.errors 
          this.$toast.info(message, {
                    position: 'top-center'
                });
        }
      }catch(error){
        this.$toast.info('Getting Error !', {
                    position: 'top-center',
                    class:'danger'
                })
      }
      this.loadingData = false;
    },
    async storePostBack(){
      this.loadingData = true;
      try{
        let response = await usercontrol.updatePostBack(this.pb);
        if(response.status == 200){
          this.$toast.info('Postback URL Updated!', {
                    position: 'top-center'
                })
              }else{
          this.$toast.info('Postback URL Not Proper!', {
                    position: 'top-center'
               });
              }
      }catch(error){
        this.$toast.info('Getting Error !', {
                    position: 'top-center',
                    class:'danger'
                })
      }
      this.loadingData = false;
    },
    async storeToken(){
      this.loadingData = true;
      try{
        let response = await usercontrol.updateToken(this.token);
        let userData = JSON.parse(localStorage.getItem('user'));
          userData.token = this.token.token;
          localStorage.setItem('user', null);
          localStorage.setItem('user', JSON.stringify(userData)); 
        if(response.status == 200){
          this.$toast.info('Token Updated!', {
                    position: 'top-center'
                })
        }
      }catch(error){
        this.$toast.info('Getting Error !', {
                    position: 'top-center',
                    class:'danger'
                })
      }
      this.loadingData = false;
    },
    async storeRights(){
      this.loadingData = true;
      try{
       this.rights.rights = this.rights.rights || [];
        let response = await usercontrol.updateRights(this.rights);
        if(response.status == 200){
          this.$toast.info('Rights Updated!', {
                    position: 'top-center'
                })
        }
      }catch(error){
        this.$toast.info('Getting Error !', {
                    position: 'top-center',
                    class:'danger'
                })
      }
      this.loadingData = false;
    },
    async getUserStatus(){
      try {
        let response = await Common.listStatus()
        let responseData = response.data.user_pb_status;
        let share_type = response.data.user_share_type;
        let rights_list = response.data.user_rights;
        let manager_list = response.data.manager_rights;
        let publisher_rights = response.data.publisher_rights;
        for (const key in rights_list) {
      this.userRights.push(rights_list[key]);
        } 
        for (const key in manager_list) {
        this.managerRights.push(manager_list[key]);
        }
        for (const key in publisher_rights) {
        this.publisherRights.push(publisher_rights[key]);
        } 
        for (const key in responseData) {
          this.user_status.push({ value: key, text: responseData[key] });
        }
        for (const key in share_type) {
          this.userShareType.push({ value: key, text: share_type[key] });
        }
        
      } catch (e) {
        console.log(e)
      }
    },
    async setRole(){
      const user = localStorage.getItem('user')
      if (user) {
        let role = JSON.parse(user)
        this.login_id=role._id
        this.isRights = role.rights
        this.isManager = role.role == 'manager'
        this.isAdvertiser = role.role == 'advertiser'
        this.isAdmin = role.role == 'admin'
        this.isPublisher = role.role == 'publisher'
      }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>