import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import Layout1 from '../layouts/Layout1'
import Default from '../layouts/BlankLayout'
/* Dashboards View */
import Dashboard from '../views/Dashboards/Dashboard1.vue'
import Dashboard1 from '../views/Dashboards/Dashboard2.vue'
/* Authentic View */
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'
import Login from '../views/AuthPages/Default/Login'
import SignUp from '../views/AuthPages/Default/SignUp'
import RecoverPassword1 from '../views/AuthPages/Default/RecoverPassword1'
import ForgetPassword1 from '../views/AuthPages/Default/ForgetPassword1.vue'
import LockScreen1 from '../views/AuthPages/Default/LockScreen1'
import ConfirmMail1 from '../views/AuthPages/Default/ConfirmMail1'
import Logout from '../views/AuthPages/Logout.vue'
/* User View */
import Profile from '../views/User/Profile'
import ProfileEdit from '../views/User/ProfileEdit'
import Relation from '../views/User/UserRelation'
import UserList from '../views/User/UserList'

/* Dictionary View */
import Country from '../views/Dictionary/Country'
import Os from '../views/Dictionary/Os'
import Browser from '../views/Dictionary/Browser'
import Carrier from '../views/Dictionary/Carrier'
import Device from '../views/Dictionary/Device'

/** Offer view */
import Offers from '../views/Offers/Offers'
import ExpiredOffer from '../views/Offers/ExpiredOffer'
import GroupOffer from '../views/Offers/GroupOffer'
import OfferCap from '../views/Offers/OfferCap'
import PubOfferPrice from '../views/Offers/PubOfferPrice'
import PubOffer from '../views/Offers/PubOffers'
import PubOfferShare from '../views/Offers/PubOfferShare'
import RejectedOffer from '../views/Offers/RejectedOffer'
import UnapprovedOffer from '../views/Offers/UnapprovedOffer'
import AgencyModel from '../views/Offers/AgencyModel.vue'

/** Logs  view*/
import DummyPostbackLogs from '../views/Logs/DummyPostbackLogs.vue'
import ClickLogs from '../views/Logs/ClickLogs'
import AuditLogs from '../views/Logs/AuditLogs'
import PostbackLogs from '../views/Logs/PostbackLogs'
import SiteLogs from '../views/Logs/SiteLogs.vue'

/** Other Features */
import LinkGen from '../views/OtherFeatures/LinkGen'
import PubCountryTarget from '../views/OtherFeatures/PubCountryTarget'
import PubEvents from '../views/OtherFeatures/PubEvents'
import S2S from '../views/OtherFeatures/S2S'
import S2SAdv from '../views/OtherFeatures/S2S_adv'
import TestPostback from '../views/OtherFeatures/TestPostback'
import ConvFlow from '../views/OtherFeatures/ConvFlow'
import Events from '../views/Dictionary/Events'
import Report from '../views/Report/Report'
import UploadDomainOffer from '../views/Offers/UploadDomainOffer.vue'

Vue.use(VueRouter)

const childRoutes = (prop) => [
  {
    path: '',
    name: prop + '.home1',
    component: Dashboard
  },
  {
    path: 'dashboard1',
    name: prop + '.home1',
    component: Dashboard1
  }
]
const reportChildRoutes = (prop) => [
  {
    path: 'report',
    name: prop + '.report',
    component: Report
  }
];
const authChildRoutes = (prop) => [
  {
    path: 'login',
    name: prop + '.login',
    component: Login
  },
  {
    path: 'signup',
    name: prop + '.signup',
    component: SignUp
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    component: RecoverPassword1
  },
  {
    path: 'reset-password1',
    name: prop + '.reset-password1',
    component: ForgetPassword1,
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    component: ConfirmMail1
  },
  {
    path: 'logout',
    name: prop + '.logout',
    component: Logout
  }
]
const userChildRoute = (prop) => [
  {
    path: 'profile',
    name: prop + '.profile',
    component: Profile
  },
  {
    path: 'profile-edit',
    name: prop + '.edit',
    component: ProfileEdit
  },
  {
    path: 'user-relation',
    name: prop + '.relation',
    component: Relation
  },
  {
    path: 'user-list',
    name: prop + '.list',
    component: UserList
  }
]

const logChildRoute = (prop) => [
  {
    path: 'auditlog',
    name: prop + '.audit',
    component: AuditLogs
  },
  {
    path: 'clicklog',
    name: prop + '.click',
    component: ClickLogs
  },
  {
    path: 'postbacklog',
    name: prop + '.postback',
    component: PostbackLogs
  },
  {
    path: 'dummypostback',
    name: prop + '.dummypostback',
    component: DummyPostbackLogs
  },
  {
    path: 'sitelog',
    name: prop + '.site',
    component: SiteLogs
  }
]

const dictionaryChildRoute = (prop) => [
  {
    path: 'event',
    name: prop + '.event',
    component: Events
  },
  {
    path: 'country',
    name: prop + '.country',
    component: Country
  },
  {
    path: 'os',
    name: prop + '.os',
    component: Os
  },
  {
    path: 'browser',
    name: prop + '.browser',
    component: Browser
  },
  {
    path: 'carrier',
    name: prop + '.carrier',
    component: Carrier
  },
  {
    path: 'device',
    name: prop + '.device',
    component: Device
  }
]

const offerChildRoute = (prop) => [
  {
    path: 'expired',
    name: prop + '.expired',
    component: ExpiredOffer
  },
  {
    path: 'group',
    name: prop + '.group',
    component: GroupOffer
  },
  {
    path: 'cap',
    name: prop + '.cap',
    component: OfferCap
  },
  {
    path: 'offers',
    name: prop + '.offers',
    component: Offers
  },
  {
    path: 'pub-price',
    name: prop + '.pub.price',
    component: PubOfferPrice
  },
  {
    path: 'pub',
    name: prop + '.pub',
    component: PubOffer
  },
  {
    path: 'pub-share',
    name: prop + '.pub.share',
    component: PubOfferShare
  },
  {
    path: 'rejected',
    name: prop + '.rejected',
    component: RejectedOffer
  },
  {
    path: 'unapproved',
    name: prop + '.unapproved',
    component: UnapprovedOffer
  },
  {
    path: 'uploaddomain',
    name: prop + '.uploaddomain',
    component: UploadDomainOffer
  },
  {
    path: 'agency',
    name: prop + '.agency',
    component: AgencyModel
  }
]

const featureChildRoute = (prop) => [
  {
    path: 'link-gen',
    name: prop + '.link.gen',
    component: LinkGen
  },
  {
    path: 'pub-country-target',
    name: prop + '.pub.country.target',
    component: PubCountryTarget
  },
  {
    path: 'pub-events',
    name: prop + '.pub.events',
    component: PubEvents
  },
  {
    path: 'test-postback',
    name: prop + '.test.postback',
    component: TestPostback
  },
  {
    path: 's2s',
    name: prop + '.s2s',
    component: S2S
  },
  {
    path: 's2s_adv',
    name: prop + '.s2s_adv',
    component: S2SAdv
  },
  {
    path: 'convflow',
    name: prop + '.convflow',
    component: ConvFlow
  }
]
const routes = [
  {
    path: '/',
    name: 'dashboard1',
    component: Layout1,
    children: childRoutes('dashboard1')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout1,
    children: authChildRoutes('auth1')
  },
  {
    path: '/report',
    name: 'report',
    component: Layout1,
    children: reportChildRoutes('report')
  },
  {
    path: '/user',
    name: 'user',
    component: Layout1,
    children: userChildRoute('user')
  },
  {
    path: '/dictionary',
    name: 'Dictionary',
    component: Layout1,
    children: dictionaryChildRoute('dictionary')
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Layout1,
    children: offerChildRoute('offers')
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Layout1,
    children: logChildRoute('logs')
  },
  {
    path: '/features',
    name: 'Features',
    component: Layout1,
    children: featureChildRoute('features')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
