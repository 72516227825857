
<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">User List</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add User" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <div class="form-group">
                <label for="name">Name</label><span class="text-danger"> *</span>
                <input type="text" class="form-control mb-0" id="name" aria-describedby="emailHelp" v-model="items.username" placeholder="Your User Name">
                <span v-if="allerros.username" :class="['label label-danger']">@{{ allerros.username[0] }}</span>
                <span v-else>Name must be alphanumeric and atleast 3 characters</span>
              </div>
              <div class="form-group">
                <label for="email">Email address</label><span class="text-danger"> *</span>
                <input type="email" class="form-control mb-0" id="email" v-model="items.email" aria-describedby="emailHelp" placeholder="Enter email">
                <span v-if="allerros.email" :class="['label label-danger']">@{{ allerros.email[0] }}</span>
              </div>
              <div class="form-group">
                <label for="password">Password</label><span class="text-danger"> *</span>
                <b-input-group>
                  <b-form-input
                  v-model="items.password"
                  type="password"
                  id="newpassword"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  placeholder="**********"
                />
                <b-input-group-append is-text @click="togglePassword('newpassword')">
                    <i v-show="showPassword == true" class="fa fa-eye" id="togglePassword" title="hide password"></i>
                    <i v-show="showPassword == false" class="fa fa-eye-slash" id="togglePassword" title="show password"></i>
                  </b-input-group-append>
                </b-input-group>
                <span v-if="allerros.password" :class="['label label-danger']">@{{ allerros.password[0] }}</span>
                <span v-else>Password must contain atleast one uppercase, one lowercase and one number and atleast 6 characters</span>
              </div>
              <div class="form-group">
                <label for="password">Confirm Password</label><span class="text-danger"> *</span>
                <input type="password" class="form-control mb-0" v-model="items.c_password" id="c_password" placeholder="Confirm Password">
                <span v-if="allerros.c_password" :class="['label label-danger']">@{{ allerros.c_password[0] }}</span>
              </div>
              <b-form-group>
                <label for="usertype">User Type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.user_type" :options="Usertypes" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">User type</b-form-select-option>
                        </template>
              </b-form-select>
              <span v-if="allerros.user_type" :class="['label label-danger']">@{{ allerros.user_type[0] }}</span>
              </b-form-group>
              <b-form-group v-if="isPublisher">
                <label for="usertype">User Share Type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.share_type" :options="userShareType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">User Share Type</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group>
              <div class="form-group" v-if="isScrubing">
                <label for="rev_share">Revenue share</label><span class="text-danger"> *</span>
                <input type="text" class="form-control mb-0" id="rev_share" v-model="items.rev_share" aria-describedby="reveShare" placeholder="Revenue share">
                <span v-if="allerros.rev_share" :class="['label label-danger']">@{{ allerros.rev_share[0] }}</span>
              </div>
              <b-form-group v-if="all_managers && !isManagerSelected && isAdmin">
              <label for="managers">Assign Managers</label><span class="text-danger"> *</span>
              <multiselect
            v-model="selected_managers" 
            :options="all_managers"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            track-by="id"
            >
        </multiselect>
              </b-form-group>
              <b-form-group v-if="isAdmin && isManagerSelected">
                <label for="rights">Rights</label><span class="text-danger"> *</span>
                <multiselect
            v-model="items.rights"
            :options="managerRights"
            class="max-z-index"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            lablabel="text"
            >
        </multiselect>
                </b-form-group>
                <b-form-group v-else-if="isAdmin && isPublisher">
                <label for="rights">Rights</label><span class="text-danger"> *</span>
                <multiselect
            v-model="items.rights"
            :options="publisherRights"
            class="max-z-index"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            lablabel="text"
            >
        </multiselect>
                </b-form-group>
                <b-form-group v-else="isAdmin">
                  <label for="rights">Rights</label><span class="text-danger"> *</span>
                <multiselect
            v-model="items.rights"
            :options="userRights"
            class="max-z-index"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            lablabel="text"
            >
        </multiselect>
                </b-form-group>
                   <br/>
              <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-for="per-page-select"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="handleFilterBlur"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="user_rows" 
              :fields="columns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" :style="setWidth(field.key)">
                  <template v-if="field.key === 'user_type'">
                    <b-form-select v-model="filters[field.key]" :options="Usertypes" prepend-top @change="handleFilterBlur">
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>
                <template v-else-if="field.key === 'status'">
                    <b-form-select v-model="filters[field.key]" :options="userStatus" prepend-top @change="handleFilterBlur">
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>
                  <template v-else>
                    <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur"></b-form-input>
                  </template>
                </td>
              </template>
              <template v-slot:cell(int_id)="data">
                    <span v-if="!data.item.editable">{{ data.item.int_id }}</span>
                    <!-- <input type="text" v-model="data.item.username" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(username)="data">
                    <span v-if="!data.item.editable">{{ data.item.username }}</span>
                    <!-- <input type="text" v-model="data.item.username" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(email)="data">
                    <span v-if="!data.item.editable">{{ data.item.email }}</span>
                    <!-- <input type="text" v-model="data.item.countrycode" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(user_type)="data">
                    <span v-if="!data.item.editable">{{ getUserTypeName(data.item.user_type) }}</span>
                    <!-- <input type="text" v-model="data.item.countrycode" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-button variant=" iq-bg-danger" @click="update(data.item)" v-if="data.item.status_text == 'INACTIVE'" size="sm">{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-success" @click="update(data.item)" v-else size="sm">{{ data.item.status_text}}</b-button>
                  </template>
                  <template v-slot:cell(action)="data">
                    <button><router-link :to="{ name: 'user.edit', query: { id: data.item._id }}"  variant=" iq-bg-success mr-1 mb-1" size="sm" ><i class="ri-ball-pen-fill m-0"></i></router-link></button>
                  <!-- <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button> -->
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        <b-col sm="3" md="2" class="my-1" v-if="user_rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import UserControl from '../../Apis/usercontrol'
import Common from '../../Apis/common'
import Multiselect from 'vue-multiselect'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.loadData()
  },
  components: {
    'multiselect':Multiselect,Loader
  },
  methods: {
    default () {
      return {
        items:{
        username: '',
        email: '',
        password: '',
        c_password: '',
        rev_share:80,
        share_type:null,
        user_type : null,
        rights: '',
      },
        editable: false,
        filter:{
          username:'',
          email:'',
          user_type:'',
          status:''
        }
      }
    },
    setWidth(field){
      if(field == 'int_id'){
        return { width: '7%' }
      }
    },  
    showModal(){
      this.items = this.default()
      this.items.rev_share=80;
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    edit(item) {
      item.editable = true
    },
    submit (item) {
      this.items = item;
      this.submitForm();
      item.editable = false
    },
    togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword = this.showPassword === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        },
    async update(item) {
      this.loadingData = true;
      this.items._id = item._id;
      this.items.status = item.status;
      try{
        let response = await UserControl.updateUserStatus(this.items)
        if(response.status == 200){
          this.listUser()
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async submitForm(){
      this.loadingData = true;
      try{
        this.items.rights = this.items.rights || [];
        let response = await UserControl.storeUser(this.items)
        if(response.status == 201){
          this.allManager()
          this.items = this.default()
          this.$refs.myModalRef.hide();
          let addedUser = response.data.user._id;
          let selectManager = [];
          if(this.selected_managers.length > 0 || this.isManager){
            this.selected_managers.forEach((element) => {
              selectManager.push(element.id)
            });
            let new_data ={};
            if(this.isManager){
              selectManager.push(this.login_id)
            }
            new_data = {user_id:addedUser,manager_id:selectManager}
            let store_manger = await UserControl.userMultiManager(new_data);
            console.log(store_manger);
            this.selected_managers = [];
          }
          this.currentPage = 1;
          this.listUser()
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete the user?')){
        try{
          let response = await UserControl.deleteUser(item)
          if(response.status == 200){
            this.listUser()
            this.default()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        this.listUser(); // Call listCountry when filter input is blurred
      },
    async listUser(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
      try{
        let response = await UserControl.listUser(params)
        if(response.status == 200){
          this.default()
          let data = response.data.data.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
            element.status_text = this.userStatus.find(x => x.value == element.status).text
          });
          this.user_rows = data
          this.totalRows = response.data.data.total
        }
        this.loadingData = false;
      }catch(error){
        console.log(error)
        this.loadingData = false;
      }
    },
    async allManager(){
      if(this.isManager){
       return false;
      }
      try{
        let response = await UserControl.listManage()
        if(response.status == 200){
          let data = response.data.data
          let options = []
          data.forEach((element) => {
            options.push({name:element.int_id+"-"+element.username,id:element._id})
            });
          this.all_managers = options;
        }
      }catch(error){
        console.log(error)
      }
    },
    async getUsertypes(){
      try {
        let response = await Common.userType()
        let responseData = response.data;
        for (const key in responseData) {
          this.Usertypes.push({ value: key, text: responseData[key] });
        }
        if(this.isManager){
          // remove manager from the user types
          this.Usertypes = this.Usertypes.filter(x => x.text != 'Manager')
        }
      } catch (e) {
        console.log(e)
      }
    },
    getUserTypeName(key){
      let user_type = this.Usertypes.find(x => x.value == key);
      return user_type.text;
    },
    async getUserStatus(){
      try {
        let response = await Common.listStatus()
        let responseData = response.data.user_status;
        let user_share_type = response.data.user_share_type;
        let rights_list = response.data.user_rights;
        let manager_list = response.data.manager_rights;
        let publisher_rights = response.data.publisher_rights;
        for (const key in rights_list) {
        this.userRights.push(rights_list[key]);
        } 
        for (const key in manager_list) {
        this.managerRights.push(manager_list[key]);
        } 
        for (const key in publisher_rights) {
        this.publisherRights.push(publisher_rights[key]);
        } 
       for (const key in responseData) {
          this.userStatus.push({ value: key, text: responseData[key] });
        }
        for (const key in user_share_type) {
          this.userShareType.push({ value: key, text: user_share_type[key] });
        }
      } catch (e) {
        console.log(e)
      }
    },
    setRole(){
      const user = localStorage.getItem('user')
      if (user) {
        this.user = JSON.parse(user)
        this.isAdmin = this.user.role == 'admin'
        this.isManager = this.user.role == 'manager'
        this.login_id = this.user._id
      }
    },
    async loadData() {
      try {
        await this.setRole()
        await this.getUsertypes()
        await this.getUserStatus()
        await this.listUser()
        await this.allManager()
      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listUser',
  },
  data () {
    return {
      columns: [
       { key: 'int_id', label: 'id', sortable: true },
       { key: 'username', label: 'Name', sortable: true },
       { key: 'email', label: 'Email', sortable: true },
       { key: 'user_type', label: 'User Type', sortable: true },
       { key: 'status', label: 'Status'},
       { key: 'action', label: 'Action'}
      ],
      filters: {
    int_id: '',   
    username: '',
    email: '',  
    user_type: '',
    status: '',
  },
      user_rows: [],
      items:{
        username: '',
        email: '',
        password: '',
        c_password: '',
        share_type:null,
        rev_share:80,
        user_type : null,
       },
       userRights: [],
       managerRights: [],
       publisherRights: [],
      allerros:{},
      Usertypes:[],
      userShareType:[],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      selected_managers: [],
      all_managers: [],
      showPassword: false,
      userStatus:[],
      isManager: false,
      isAdmin:false,
      login_id:'',
      loadingData:true
    }
  },
  computed: {
    allFieldsValid() {
          return this.items.username && this.items.email && this.items.password && this.items.c_password && this.items.user_type;
      },
      isManagerSelected() {
        if(this.isAdmin){
          if(this.items.user_type != null)
          return this.items.user_type === this.Usertypes.find(option => option.text === 'Manager').value;
        }
      },
      isPublisher(){
          if(this.items.user_type != null)
          return this.items.user_type === this.Usertypes.find(option => option.text === 'Publisher').value;
      },
      isScrubing(){
        if(this.items.share_type != null){
          return this.items.share_type === this.userShareType.find(option =>option.text === 'SCRUBBING').value;
        }
      }

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>
