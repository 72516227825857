<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Browser</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add Browser" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <b-form-group label="Browser Name" label-for="name">
                  <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group label="Version" label-for="code">
                  <b-form-input id="version" type="text" placeholder="Browser version" v-model="items.version"></b-form-input>
                  <span v-if="allerros.version" :class="['label label-danger']">@{{ allerros.version[0] }}</span>
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                <div>Total Rows : {{ totalRows}}</div>
              </b-col>
                <b-col sm="0" md="3" class="my-1" style="float: right;">
        <b-form-group
          label="Page"
          label-for="per-page-select"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="listBrowser"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
                <b-table bordered hover 
                :items="rows" 
                :fields="columns"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                >
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" >
                    <b-form-input  v-model="filters[field.key]" :placeholder="field.label" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                  </td>
                </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                    <span v-if="allerros.name && data.item.editable" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                  </template>
                  <template v-slot:cell(version)="data">
                    <span v-if="!data.item.editable">{{ data.item.version }}</span>
                    <input type="text" v-model="data.item.version" v-else class="form-control">
                    <span v-if="allerros.version && data.item.editable" :class="['label label-danger']">@{{ allerros.version[0] }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
          <b-col sm="3" md="2" class="my-1" v-if="rows && rows.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Dictionary from '../../Apis/dictionary'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
    this.listBrowser()
    this.loadingData = false
  },
  components: {
    Loader
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        name: '',
        version: '',
        },
        editable: false,
        allerros:{}
      }
    },
    edit(item) {
      if(this.editable_master == true){
          return false;
        }
        this.editable_master = true;
      item.editable = true
      this.allerros = {}  
        this.rows.forEach((element) => {
          if(element._id != item._id){
            element.editable = false
          }
        });
    },
    showModal(){
      this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    submit (item) {
      this.items = item;
      this.submitForm();
    },
    async submitForm(){
      this.loadingData=true;
      try{
        let response = await Dictionary.storeBrowser(this.items)
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          console.log(response.message);
          if(this.editable_master == false){
              this.currentPage = 1;
            }
          this.listBrowser()
          this.default()
          this.items = {};
          this.allerros={}
          this.$refs.myModalRef.hide();
          this.editable_master=false;
        }else{
          console.log(response);
          this.allerros = response.data.errors
        }
        this.loadingData=false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this browser?')){
        try{
          let response = await Dictionary.deleteBrowser(item._id)
          if(response.status == 200){
            this.listBrowser()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        this.listBrowser(); // Call listCountry when filter input is blurred
      },
    async listBrowser(){
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
      try{
        let response = await Dictionary.listBrowser(params)
        if(response.status == 200){
          let data = response.data.data.data
          
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
          });
          this.rows = data
          this.totalRows = response.data.data.total
        }
      }catch(error){
      }
    }
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listBrowser',
  },
  data () {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Version', key: 'version', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      rows: [],
      items: {
        _id:'',
        name: '',
        version: '',
      },
      filters: {
        name: '',
        version: '',
      },
      allerros:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      loadingData: true,
      filterOn: [],
      editable_master:false
    }
  },
  computed: {
    allFieldsValid() {
          return this.items.name && this.items.version;
      }
  }
}
</script>
