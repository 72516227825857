<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Unapproved Offers</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button @click="showModal" variant="primary">Add New</b-button> -->
            <b-modal ref="myModalRef" id="modal-4" size="xl" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <b-row>
                  <b-col md="6">
                <b-form-group label="Name" label-for="name">
                  <b-form-input id="name" type="text" placeholder="Enter Name" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group label="url" label-for="url">
                  <b-form-textarea id="url" rows="4" placeholder="Url" v-model="items.url"></b-form-textarea>
                  <span v-if="allerros.url" :class="['label label-danger']">@{{ allerros.url[0] }}</span>
                </b-form-group>
                <b-form-group label="Price" label-for="price">
                  <b-form-input id="price" type="text" placeholder="Enter Price" v-model="items.price"></b-form-input>
                  <span v-if="allerros.price" :class="['label label-danger']">@{{ allerros.price[0] }}</span>
                </b-form-group>
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea id="description" rows="4" placeholder="Description" v-model="items.description"></b-form-textarea>
                  <span v-if="allerros.description" :class="['label label-danger']">@{{ allerros.description[0] }}</span>
                </b-form-group>
                <b-form-group >
                  <label for="customFile">File</label> &nbsp;<a v-if="items.icon" :href="items.icon" target="_blank">Already Uploaded file</a>
                <b-form-file
                  placeholder="Choose a file"
                  id="customFile"
                  v-model="items.file"
                ></b-form-file>
              </b-form-group>
              <b-form-group>
                <label for="usertype">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.active" :options="active_status" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
                </b-col>
                <b-col md="6">
              <b-form-group>
                <label for="usertype">Device type</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.device_type" :options="deviceType" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Device Type</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
                <b-form-group label="cap" label-for="code">
                  <b-form-input id="cap" type="text" placeholder="Caps" v-model="items.cap"></b-form-input>
                  <span v-if="allerros.cap" :class="['label label-danger']">@{{ allerros.cap[0] }}</span>
                </b-form-group>
                <b-form-group>
                <label for="usertype">Conversion Flow</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.conv_flow_id" :options="convFlow" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Flow</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
              <b-form-group>
                <label for="usertype">Pricing Model</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.pricing_model" :options="pricingModels" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
              <b-form-group label="Notes" label-for="note">
                  <b-form-textarea id="note" rows="4" placeholder="Notes" v-model="items.note"></b-form-textarea>
                  <span v-if="allerros.note" :class="['label label-danger']">@{{ allerros.note[0] }}</span>
                </b-form-group>
                <b-form-group label="Expired_at" label-for="code">
                  <b-form-input id="exampleInputdate" type="date" v-model="items.expire_date"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
                <b-button type="submit" variant="primary">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                <div>Total Rows : {{ totalRows}}</div>
              </b-col>
                <b-col sm="0" md="3" class="my-1" style="float: right;">
        <b-form-group
          label="Page"
          label-for="per-page-select"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="handleFilterBlur"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
                <b-table bordered hover 
                :items="u_offer_rows" 
                :fields="columns"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                >
                <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" :style="setWidth(field.key)" >
                  <template v-if="field.key === 'select_id'">
                    <input type="checkbox" v-model="isChecked" @click="getAllCheckedRecordId()">
                  </template>
                  <template v-if="field.key === 'action'  && checkedItems.length >0 ">
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="updateAll(1)"><i class="ri-check-fill m-0" title="approved"></i></b-button>
                  <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="updateAll(4)" ><i class="ri-shield-cross-fill m-0" title="Rejected"></i></b-button> 
                  <b-button variant=" iq-bg-danger" size="sm" @click="removeAll"><i class="ri-delete-bin-line m-0" title="Delete"></i></b-button>
                  </template>
                  
                  <template v-if="field.key === 'advertiser_name'">
                    <multiselect
                    v-model="advertiserNameFilter"
                    :options="[ { value: 'null', text: 'All' }, ...advertisers ]"
                    @select="handleFilterBlur"
                    @remove="handleFilterRemove('advertiser')"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
                </template>

                <template v-else-if="field.key === 'pricing_model'">
                    <b-form-select v-model="filters[field.key]" :options="pricingModels" @change="handleFilterBlur" prepend-top>
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>

                <template v-else>
                  <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                </template>
                </td>
              </template>
              <template v-slot:cell(select_id)="data">
                      <input type="checkbox" :value="data.item._id" v-model="checkedItems" @click="checkValues(data.item._id)">   
                  </template>
                   
                <template v-slot:cell(int_id)="data">
                    <span v-if="!data.item.editable">{{ data.item.int_id }}</span>
                    <input type="text" v-model="data.item.int_id" v-else class="form-control">
                    
                  </template>
                  <template v-slot:cell(name)="data">
                    <!-- <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control"> -->
                    <a :href="data.item.url" target="_blank" :title="data.item.url">{{ data.item.name }}</a>
                  </template>
                  <template v-slot:cell(pricing_model)="data">
                    <span v-if="!data.item.editable">
                     <span v-for="l in pricingModels" :key="l.value">
                     <span v-if="data.item.pricing_model == l.value">
                       {{ l.text }}
                    </span>
                    </span>
                    </span> 
                      <input type="text" v-model="data.item.pricing_model" v-else class="form-control">
                  </template>
                  <template v-slot:cell(price)="data">
                    <span v-if="!data.item.editable">{{ data.item.price }}</span>
                    <input type="text" v-model="data.item.price" v-else class="form-control">
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="update(data.item,1)" v-if="!data.item.editable"><i class="ri-check-fill m-0" title="approved"></i></b-button>
                    <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="update(data.item,4)" v-if="!data.item.editable"><i class="ri-shield-cross-fill m-0" title="Rejected"></i></b-button>
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-eye-fill m-0" title="View"></i></b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0" title="Delete"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
          <b-col sm="3" md="2" class="my-1" v-if="u_offer_rows.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import Common from '../../Apis/common'
import UserControl from '../../Apis/usercontrol'
import Loader from '../../components/Loader.vue'
import Multiselect from 'vue-multiselect'



export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
    },
    components: {
       Loader,Multiselect
    },
  data () {
    return {
      columns: [
      { label: 'Select', key: 'select_id', class: 'text-left' },
        { label: 'Int_id', key: 'int_id', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Advertiser', key: 'advertiser_name', class: 'text-left' },
        { label: 'Pricing Model', key: 'pricing_model', class: 'text-left' },
        { label: 'Price', key: 'price', class: 'text-left'},
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      u_offer_rows: [],
      items: {
        _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:''
        },
      filters: {
        name: '',
        advertiser_name: '',
        price: '',
        int_id:'',
        pricing_model:''
      },
      allerros:{},
      deviceType:[],
      convFlow:[],
      checkedItems:[],
      isChecked: false,
      active_status:[],
      pricingModels:[],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterOn: [],
      advertisers:[],
      checkedRecordId: [],
      loadingData:true,
      advertiserNameFilter: null,

    }
  },
  methods: {
    default () {
      return {
        items:{
          _id:'',
        name: '',
        description: '',
        url: '',
        price: '',
        pricing_model:null,
        cap:'',
        device_type :null,
        conv_flow_id:null,
        note:'',
        active:null,
        expire_date:''
        },
        editable: false
      }
    },
    setWidth(field){
     
      if(field == 'advertiser_name'){
        return { width: '20%' }
      }
      
    },
    getAllCheckedRecordId(){
      this.checkedItems = [];
      if(!this.isChecked){
       for (const key in this.u_offer_rows) {
            this.checkedItems.push(this.u_offer_rows[key]._id);
        }
      }
    },
    checkValues(id) {
      if (this.checkedItems.includes(id)) {
        // If checkbox is already checked, remove it
        this.checkedItems = this.checkedItems.filter(item => item !== id);
      } else {
        // If checkbox is not checked, add it
        this.checkedItems.push(id);
      }
    },
    handleFilterBlur() {
      if(this.advertiserNameFilter !== null && typeof this.advertiserNameFilter == 'object'){
          this.filters.advertiser_name = this.advertiserNameFilter.value;
      }
      this.currentPage = 1; // Reset the current page when filtering
        this.listUnapprovedOffer(); // Call listCountry when filter input is blurred
      },
      handleFilterRemove(filterType) {
      if (filterType === 'advertiser') {
        this.advertiserNameFilter = null;
        this.filters.advertiser_name = '';
      }
      this.currentPage = 1;
      this.listUnapprovedOffer();
    },
    edit(item) {
      this.items = item;
      this.allerros={};
      this.$refs.myModalRef.show();
    },
    async update(item,active) {
      this.loadingData = true;
      this.items._id = item._id;
      this.items.active = active;
      try{
        let response = await Offer.updateStatusByStatus(this.items)
        if(response.status == 200){
          if(active == 1){
            this.$toast.info('Offer Approved!', {
                    position: 'top-center'
                })
          }
          if(active == 4){
            this.$toast.info('Offer Rejected!', {
                    position: 'top-center',
                    class:'danger'
                })
          }
          this.listUnapprovedOffer()
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async updateAll(active) {
      this.loadingData = true;
      const ids = this.checkedItems;
      try{
        let response = await Offer.updateAllStatusByStatus({"ids":ids,"status":active})
        
        if(response.status == 200){
          if(active == 1){
            this.$toast.info('Offer Approved!', {
                    position: 'top-center'
                })
          }
          if(active == 4){
            this.$toast.info('Offer Rejected!', {
                    position: 'top-center',
                    class:'danger'
                })
          }
          this.listUnapprovedOffer()
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    submit (item) {
      this.items = item;
      this.submitForm();
      item.editable = false
    },
    async submitForm(){
      this.loadingData = true;
      const formData = new FormData()
      formData.append('name', this.items.name);
      formData.append('description', this.items.description);
      formData.append('url', this.items.url);
      formData.append('price', this.items.price);
      formData.append('pricing_model', this.items.pricing_model);
      formData.append('cap', this.items.cap);
      formData.append('device_type', this.items.device_type);
      formData.append('conv_flow_id', this.items.conv_flow_id);
      formData.append('note', this.items.note);
      formData.append('active', this.items.active);
      formData.append('expire_date', this.items.expire_date);
      formData.append('type', this.items.type);
      formData.append('blocking_type', this.items.blocking_type);
      if(this.items._id){
        formData.append('_id', this.items._id);
      }
      formData.append('file', this.items.file);
      // console.log('forrr',formData);
      try{
        let response = await Offer.storeOffer(formData)
        if(response.status == 201){
          // console.log(response.message);
          this.listUnapprovedOffer()
          this.items = this.default()
          this.$refs.myModalRef.hide();
        }else{
          // console.log(response);
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Offer?')){
        try{
          let response = await Offer.deleteOffer(item)
          if(response.status == 200){
            this.listUnapprovedOffer()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    async removeAll () {
      if(confirm('Are you sure to delete this Offer?')){
        try{
          const ids = this.checkedItems;
          let response = await Offer.deleteAllOffer({"ids":ids})
          if(response.status == 200){
            this.listUnapprovedOffer()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    async listUnapprovedOffer(){
      this.loadingData = true;
      try{
        const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters
      };
        let response = await Offer.listUnapprovedOffer(params)
        if(response.status == 200){
          let data = response.data.offers.data
  
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false

            const advertiser = this.advertisers.find(x => x.value == element.user_id);
            element.advertiser_name = advertiser ? advertiser.text : ''; 

            // const pricing_status = this.pricingModels.find(x => x.value == element.pricing_model);
            // element.pricing_model = pricing_status ? pricing_status.text : '';

          });
          
          this.u_offer_rows = data
          this.totalRows =response.data.offers.total
        }
        this.loadingData = false;
      }catch(error){

        this.loadingData = false;
      }
    },
    async listStatus(){
      try{
        let response = await Common.listStatus()
        if(response.status == 200){
          let p_model = response.data.pricing_model
          let d_type = response.data.device_type
          let a_status = response.data.offer_status
          
          //pricing model
          for(const key in p_model){
            this.pricingModels.push({ value: key, text: p_model[key] })
          }

          //device type
          for(const key in d_type){
            this.deviceType.push({ value: key, text: d_type[key] })
          }

          //active status
          for(const key in a_status){
            this.active_status.push({ value: key, text: a_status[key] })
          }
        }
      }catch(error){
      }
    },

    async listAdvertiser(){
      try{
        let response = await UserControl.listAdvertiser()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.advertisers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
      }catch(error){
      }
    },

    setNext10YearsDate() {
      const today = new Date();
      const next10Years = new Date(today.getFullYear() + 10, today.getMonth(), today.getDate());
      this.items.expire_date = next10Years.toISOString().substr(0, 10);
    },
    async activeConvFlow(){
      try{
        let response = await Offer.activeConvFlow()
        if(response.status == 200){
          let data = response.data.convflows
          //store value as a _id and text as a name
          for(const key in data){
            this.convFlow.push({ value: data[key]._id, text: data[key].name })
          }
        }
      }catch(error){
      }
    },
    async loadData() {
      try {
        await this.listAdvertiser()
        await this.listStatus()
        await this.listUnapprovedOffer()
        await this.activeConvFlow()
        await this.setNext10YearsDate()
      } catch (error) {
        console.error('Error loading data:', error);
      }
  },
  
  
  },
  created(){
    this.loadData()
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listUnapprovedOffer',
  },
  computed: {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>