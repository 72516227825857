<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Conversion Flow</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Add Flow" hide-footer>
              <form class="mt-4" @submit.prevent="submitForm">
                <b-form-group>
                  <label for="Name">Flow</label><span class="text-danger"> *</span>
                  <b-form-input id="name" type="text" placeholder="Enter Flow type" v-model="items.name"></b-form-input>
                  <span v-if="allerros.name" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                </b-form-group>
                <b-form-group> <label for="description">Description</label><span class="text-danger"> *</span>
                  <b-form-textarea id="description" v-model="items.description" rows="3"> </b-form-textarea>
                  <span v-if="allerros.description" :class="['label label-danger']">@{{ allerros.description[0] }}</span>
                </b-form-group>
                <b-form-group>
                <label for="usertype">Status</label><span class="text-danger"> *</span>
                      <b-form-select plain v-model="items.status" :options="conv_status" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
              </b-form-select>
              </b-form-group> 
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
                <div>Total Rows : {{ totalRows}}</div>
              </b-col>
                <b-col sm="0" md="3" class="my-1" style="float: right;">
        <b-form-group
          label="Page"
          label-for="per-page-select"
          label-cols-sm="2"
          label-cols-md="2"
          label-cols-lg="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            @change="handleFilterBlur"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>
                <b-table bordered hover 
                :items="rows" 
                :fields="columns"  
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                >
                <template slot="top-row" slot-scope="{ fields }">
                  <td v-for="field in fields" :key="field.key" >
                    <b-form-input  v-model="filters[field.key]" :placeholder="field.label" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                  </td>
                </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                    <span v-if="allerros.name && data.item.editable" :class="['label label-danger']">@{{ allerros.name[0] }}</span>
                  </template>
                  <template v-slot:cell(description)="data">
                    <span v-if="!data.item.editable">{{ data.item.description }}</span>
                    <b-form-textarea v-model="data.item.description" rows="3" v-else class="form-control" />
                    <span v-if="allerros.description && data.item.editable" :class="['label label-danger']">@{{ allerros.description[0] }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-button variant=" iq-bg-danger" @click="update(data.item)" v-if="data.item.status_text == 'INACTIVE'" size="sm">{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-success" @click="update(data.item)" v-else size="sm">{{ data.item.status_text}}</b-button>
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
          <b-col sm="3" md="2" class="my-1" v-if="rows && rows.length > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          prev-text="Previous"
          next-text="Next"
          first-text="First"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import Common from '../../Apis/common'
import Loader from '../../components/Loader.vue'

export default {
  name: 'convflow',
  mounted () {
    sofbox.index()
    this.loadData()
  },
  components: {
    Loader
  },
  watch: {
    // Watch for changes in currentPage
    currentPage: 'listConvFlow',
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        name: '',
        description: '',
        status: '',
        },
        editable: false,
        allerros:{},
      }
    },
    handleFilterBlur() {
      this.currentPage = 1; // Reset the current page when filtering
        this.listConvFlow(); // Call listCountry when filter input is blurred
    },
    edit(item) {
      if(this.editable_master == true){
          return false;
        }
      this.editable_master = true;
      item.editable = true
      this.allerros = {}  
      this.rows.forEach((element) => {
        if(element._id != item._id){
          element.editable = false
        }
      });
    },
    showModal(){
      this.items = this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    submit (item) {
      this.items = item;
      this.submitForm();
      // item.editable = false
    },
    async update(item) {
      this.loadingData = true;
      this.items._id = item._id;
      this.items.status = item.status;
      try{
        let response = await Offer.updateConvFlowStatus(this.items)
        if(response.status == 200){
          this.$toast.info('Status Updated Successfully', {
                    position: 'top-center'
                });
          this.listConvFlow()
        }else{
          this.allerros = response.data.errors
        }
      }catch(error){

      }
      this.loadingData = false;
    },
    async submitForm(){
      this.loadingData = true;
      try{
        let response = await Offer.storeConvFlow(this.items)
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          this.listConvFlow()
          this.items = this.default()
          this.$refs.myModalRef.hide();
          this.editable_master=false;
        }else{
          this.allerros = response.data.errors
        }
      }catch(error){

      }
      this.loadingData = false;
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Conversion flow?')){
        try{
          let response = await Offer.deleteConvFlow(item._id)
          if(response.status == 200){
            this.listConvFlow()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    },
    async listConvFlow(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters,
      };
      try{
        let response = await Offer.listConvFlow(params)
        if(response.status == 200){
          let data = response.data.data.data
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
            element.status_text = this.conv_status.find(x => x.value == element.status).text
          });
          this.rows = data
          this.totalRows = response.data.data.total
        }
      }catch(error){
      }
      this.loadingData = false;
    },
    async listStatus(){
      try{
        let response = await Common.listStatus()
        if(response.status == 200){
          let e_status = response.data.conv_flow_status
          
          for(const key in e_status){
            this.conv_status.push({ value: key, text: e_status[key] })
          }
        }
      }catch(error){
      }
    },
    async loadData() {
      try {
        await this.listStatus();
        await this.listConvFlow();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    }
  },
  data () {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Description', key: 'description', class: 'text-left' },
        {label: 'Status', key: 'status', class: 'text-left'},
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      rows: [],
      items: {
        _id:'',
        name: '',
        description: '',
        status: '',
      },
      filters: {
        name: '',
        description: '',
      },
      conv_status: [],
      allerros:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      loadingData:false,
      editable_master: false,
    }
  },
  computed: {
    allFieldsValid() {
          return this.items.name && this.items.description && this.items.status;
      },
  }
}
</script>
