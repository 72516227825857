<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Promotion Link Generetor</h4>
          </template>
          <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <b-row>
                  <b-col md="12">
                    <b-form-group label="Main Domain" label-for="Base URL">
                  <b-form-input id="base_url" type="text" placeholder="" v-model="items.hosts" ></b-form-input>
                </b-form-group>
              </b-col>
                <b-col md="6">
                  <label for="date_range">Publisher</label>
                <multiselect
                    v-model="items.user"
                    :options="users"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
            </b-col>
            <b-col md="6">
                  <label for="date_range">Offers</label>
                <multiselect
                    v-model="items.offer"
                    :options="offers"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
            </b-col>
            </b-row>
            <br/>
            <b-button type="submit" variant="primary">Ganerate</b-button>
              </form>
              <br/>
              <template v-if="links != ''">
                <b-form-group>
                  <b-form-input id="link" :disabled="true" type="text" v-model="links" @click="copyText"></b-form-input>
                </b-form-group>
              </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Common from '../../Apis/common'
import UserControl from '../../Apis/usercontrol'
import Offer from '../../Apis/offer'
import DateRangePicker from 'vue2-daterange-picker'
import Multiselect from 'vue-multiselect'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Loader from '../../components/Loader.vue'
export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.listUsers()
    this.listOffers()
  },
  components: {
    DateRangePicker,Multiselect,Loader
  },
  data () {
    return {
      dateRange:{
        startDate: new Date(),
        endDate: new Date()
      },
      items: {
        hosts:process.env.VUE_APP_BASE_URL,
        user:'',
        offer:''
      },
      links:'',
      users:[],
      offers:[],
      loadingData:true,
    }
  },
  methods: {
    checkValue(value){
      if(value == null || value == undefined || value == ''){
        return ''
      }else{
        return value
      }
    },
    async copyText() {
      try {
      await navigator.clipboard.writeText(this.items);
      this.$toast.info('Promotion URL copied!', {
                    position: 'top-center'
                })
    } catch($e) {
    }
    },
    async submitForm(){
      this.loadingData = true;
      const transformedJson = {
        user: this.checkValue(this.items.user.value),
        offer: this.checkValue(this.items.offer.value)
      };
      console.log(transformedJson);
      this.links = this.items.hosts+"site/click?offer_id="+transformedJson.offer+"&user_id="+transformedJson.user;
      this.loadingData=false;
    },
    async listUsers(){
      this.loadingData = true;
      try{
        let response = await UserControl.listPublisher()
        if(response.status == 200){
          let data = response.data.data
          console.log(data);
          for(const key in data){
            this.users.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listOffers(){
      this.loadingData = true;
      try{
        let response = await Offer.listActiveOffer()
        if(response.status == 200){
          let data = response.data.data
          console.log(data);
          for(const key in data){
            this.offers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].name})
          }
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'submitForm',
  },
  computed: {
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.filter:focus-within .multiselect__content-wrapper {
    width: 300px!important;
}
</style>