<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <loader :isLoading="loadingData" />
          <template v-slot:headerTitle>
            <h4 class="card-title">Publisher Wise Country</h4>
          </template>
          <template v-slot:headerAction>
            <b-button @click="showModal" variant="primary">Add New</b-button>
            <b-modal ref="myModalRef" id="modal-4" size="lg" title="Set Country Targeting for publisher" hide-footer>
              <form class="mt-4" id="puboffershare" @submit.prevent="submitForm">
                <b-form-group>
                <label for="usertype">publisher</label><span class="text-danger"> *</span>
                <multiselect
                    v-model="items.user_id"
                    :options="publishers"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group>
              <b-form-group>
                <label for="usertype">Offer</label><span class="text-danger"> *</span>
                <multiselect
                    v-model="items.offer_id"
                    :options="offers"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
              </b-form-group> 
              <b-form-group>
                <label for="country">Country</label><span class="text-danger"> *</span>
                <multiselect
            v-model="items.country" 
            :options="countries"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Pick some"
            label="name"
            track-by="id"
            >
        </multiselect>
                </b-form-group>
              <b-form-group>
                <label for="status">Status</label><span class="text-danger"> *</span>
                  <b-form-select plain v-model="items.status" :options="user_status" size="lg">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Status</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="!allFieldsValid">Submit</b-button>
              </form>
            </b-modal>
          </template>
          <template>
            <b-row>

              <b-col md="12" class="table-responsive">
                <b-col sm="0" md="3" class="my-1" style="float: left;">
              <div>Total Rows : {{ totalRows}}</div>
            </b-col>
              <b-col sm="0" md="3" class="my-1" style="float: right;">
      <b-form-group
        label="Page"
        label-cols-sm="2"
        label-cols-md="2"
        label-cols-lg="2"
        label-align-sm="right"
        label-size="sm"
        class="mb-0"
      >
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="handleFilterBlur"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </b-col>
              <b-table bordered hover 
              :items="pub_country_rows" 
              :fields="columns"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              >
              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key" >
                  <template v-if="field.key === 'user'">
                    <multiselect
                    v-model="publisherNameFilter"
                    :options="[ { value: 'null', text: 'All' }, ...publishers ]"
                    @select="handleFilterBlur"
                    @remove="handleFilterRemove('publisher')"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
                </template>
                <template v-else-if="field.key === 'offer'">
                    <multiselect
                    v-model="offerNameFilter"
                    :options="[ { value: 'null', text: 'All' }, ...offers ]"
                    @select="handleFilterBlur"
                    @remove="handleFilterRemove('offer')"
                    :multiple="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    label="text"
                    track-by="value"
                />
                </template>
                <template v-else-if="field.key === 'status'">
                    <b-form-select v-model="filters[field.key]" :options="user_status" @change="handleFilterBlur" prepend-top>
                      <template #first>
                        <b-form-select-option :value="null">All</b-form-select-option>
                      </template>
                    </b-form-select>
                </template>
                <template v-else>
                  <b-form-input v-model="filters[field.key]" v-if="filters.hasOwnProperty(field.key)" @blur="handleFilterBlur" @keyup.enter="handleFilterBlur"></b-form-input>
                </template>
                </td>
              </template>
                  <template v-slot:cell(offer)="data">
                    <span>{{ data.item.offer }}</span>
                    <!-- <input type="text" v-model="data.item.offer" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(user)="data">
                    <span>{{ data.item.user }}</span>
                    <!-- <input type="text" v-model="data.item.user" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(country)="data">
                    <span v-if="!data.item.editable">{{ data.item.country }}</span>
                    <multiselect v-else
                      v-model="selected_county" 
                      :options="countries"
                      class="max-z-index"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      label="name"
                      track-by="id"
                      >
                  </multiselect>
                  </template>
                  <template v-slot:cell(status)="data">
                    <b-button variant=" iq-bg-danger" @click="update(data.item)" v-if="data.item.status_text == 'INACTIVE'" size="sm">{{ data.item.status_text}}</b-button>
                    <b-button variant=" iq-bg-success" @click="update(data.item)" v-else size="sm">{{ data.item.status_text}}</b-button>
                  </template>
                  <template v-slot:cell(action)="data">
                     <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          <template>
        <b-col sm="3" md="2" class="my-1" v-if="pub_country_rows.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        prev-text="Previous"
        next-text="Next"
        first-text="First"
        last-text="Last"
      ></b-pagination>
    </b-col>
  </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Offer from '../../Apis/offer'
import UserControl from '../../Apis/usercontrol'
import Common from '../../Apis/common'
import Dictionary from '../../Apis/dictionary'
import Multiselect from 'vue-multiselect'
import Loader from '../../components/Loader.vue'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index(),
    this.loadData()
  },
  components: {
    'multiselect':Multiselect,Loader
  },
  methods: {
    default () {
      return {
        items:{
        _id:'',
        user_id: '',
        offer_id: '',
        country:'',
        status: '',
        },
        editable: false,
        allerros:{},
      }
    },
    showModal(){
      this.items=this.default()
      this.allerros = {}
      this.$refs.myModalRef.show();
    },
    edit(item) {
      if(this.editable_master == true){
          return false;
        }
      this.editable_master = true;
      item.editable = true
      this.allerros = {}  
      this.pub_country_rows.forEach((element) => {
        if(element._id != item._id){
          element.editable = false
        }else{
          const country_array = item.country.split(',');
          for(const key in country_array){
            this.selected_county.push({id:country_array[key],name:country_array[key]});
          }
        }
      });
    },
    async update(item) {
      this.loadingData = true;
      this.items._id = item._id;
      this.items.status = item.status;
      try{
        let response = await Offer.updatePubCountryStatus(this.items)
        if(response.status == 200){
          this.$toast.info('Status Updated Successfully!', {
                    position: 'top-center'
                });
          this.listPubCountry()
        }else{
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    submit (item) {
      this.items = item;
      this.items.country = this.selected_county;
      this.submitForm();
    },
    async submitForm(){
      this.loadingData = true;
      this.items.user_id = this.checkValue(this.items.user_id);
      this.items.offer_id = this.checkValue(this.items.offer_id);
      try{
        let country_opt=[];
        this.items.country.forEach((element) => {
          country_opt.push(element.id);
        });
        this.items.country = country_opt.join(',');
        let response = await Offer.storePubCountry(this.items)
        if(response.status == 201){
          const message = response.data.message;
          this.$toast.info(message, {
                    position: 'top-center'
                });
          this.listPubCountry()
          this.items = this.default()
          this.$refs.myModalRef.hide();
          this.editable_master = false;
        }else{
          console.log(response);
          this.allerros = response.data.errors
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    checkValue(value){
      if(value.value){
        return value.value
      }else{
        return value
      }
    },
    async remove (item) {
      if(confirm('Are you sure to delete this Targeting?')){
        try{
          let response = await Offer.deletePubCountry(item._id)
          if(response.status == 200){
            this.listPubCountry()
          }else{
            this.allerros = response.data.errors
          }
        }catch(error){
        }
      }
    }, handleFilterBlur() {
      if(this.publisherNameFilter !== null && typeof this.publisherNameFilter == 'object'){
          this.filters.user = this.publisherNameFilter.value;
      }
      if(this.offerNameFilter !== null && typeof this.offerNameFilter == 'object'){
        this.filters.offer = this.offerNameFilter.value;
      }
      this.currentPage = 1; // Reset the current page when filtering
        this.listPubCountry(); // Call listCountry when filter input is blurred
      },
    handleFilterRemove(filterType) {
      if (filterType === 'publisher') {
        this.publisherNameFilter = null;
        this.filters.user = '';
      } else if (filterType === 'offer') {
        this.offerNameFilter = null;
        this.filters.offer = '';
      }
      this.currentPage = 1;
      this.listPubCountry();
    },
    async listPubCountry(){
      this.loadingData = true;
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        ...this.filters
      };
     
      try{
        let response = await Offer.listPubCountry(params)
        if(response.status == 200){
          let data = response.data.data.data
          //add editable and incremental id in each row
          data.forEach((element) => {
            element.editable = false
            const userD = this.publishers.find(x => x.value == element.user_id);
            element.user = userD ? userD.text : '';
            const offerD = this.offers.find(x => x.value == element.offer_id);
            element.offer = offerD ? offerD.text : '';
            const statusD = this.user_status.find(x => x.value == element.status);
            element.status_text = statusD ? statusD.text : '';
          });
          this.pub_country_rows = data
         
          this.totalRows = response.data.data.total
        }
        this.loadingData = false;
      }catch(error){
        this.loadingData = false;
      }
    },
    async listPublisher(){
      try{
        let response = await UserControl.listPublisher()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.publishers.push({ value: data[key]._id, text: data[key].int_id+'-'+data[key].username})
          }
        }
      }catch(error){
      }
    },
    async listActiveOffer(){
      try{
        let response = await Offer.listActiveOffer()
        if(response.status == 200){
          let data = response.data.data
          for(const key in data){
            this.offers.push({ value: data[key]._id, text:data[key].int_id+'-'+data[key].name })
          }
        }
      }catch(error){
      }
    },
    async getUserStatus(){
      try {
        let response = await Common.listStatus()
        let responseData = response.data.pubCountryStatus;
        for (const key in responseData) {
          this.user_status.push({ value: key, text: responseData[key] });
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCountries(){
      try {
        let response = await Dictionary.getCountries();
        let responseData = response.data;
        let options = []
        responseData.forEach((element) => {
            options.push({ id: element.alpha_2, name: element.name+' ('+element.alpha_2+')' })
            });
          this.countries = options;
      } catch (e) {
        console.log(e)
      }
    },
    async loadData() {
        try {
          await this.getUserStatus(),
          await this.getCountries(),
          await this.listPublisher(),
          await this.listActiveOffer(),
          await this.listPubCountry()
        } catch (error) {
          console.error('Error loading data:', error);
        }
      }
  },
  data () {
    return {  
      columns: [
        { label: 'Publisher', key: 'user', class: 'text-left', sortable: true },
        { label: 'Offer', key: 'offer', class: 'text-left', sortable: true },
        { label: 'Country', key: 'country', class: 'text-left', sortable: true },
        { label: 'status', key: 'status', class: 'text-left', sortable: true },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      filters: {
        user: '',
        offer: '',
        status: '',
        country:'',
      },
      pub_country_rows: [],
      items: {
        _id:'',
        offer_id: null,
        user_id: null,
        share: 0,
      },
      allerros:{},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10,20,50,100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      publishers:[],
      offers:[],
      countries:[],
      selected_county:[],
      user_status:[],
      editable_master:false,
      loadingData:true,
      publisherNameFilter: null,
      offerNameFilter: null
    }
  },
  watch: {
    // Watch for changes in currentPage and fetchData accordingly
    currentPage: 'listPubCountry',
  },
  computed: {
    allFieldsValid() {
          return this.items.user_id && this.items.offer_id && this.items.status && this.items.country;
      },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.table-responsive{
  overflow-x:unset !important ;
}
</style>
