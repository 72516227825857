<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Test Postback Url</h4>
          </template>
          <form class="mt-4" @submit.prevent="submitForm" style="margin-left: 1%;margin-right: 1%;">
            <b-row>
                  <b-col md="12">
                  <b-form-input type="text" placeholder="Enter Postback Url" v-model="items.url" ></b-form-input>
              </b-col>
            </b-row>
            <br/>
            <b-button type="submit" variant="primary">Submit</b-button>
              </form>
              <br/>
               <!-- Display response data here -->
          <div v-if="responseData">
            <h5>Response Data:</h5>
            <b-form-textarea id="response_data" v-model="formattedResponseData" rows="5" readonly></b-form-textarea>
          </div>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
  
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import Common  from '../../Apis/common'

export default {
  name: 'UiDataTable',
  mounted () {
    sofbox.index()
  },
  methods: {
    default () {
      return {
        items:{
        url: '',
        },
        editable: false,
        responseData: null
      }
    },
    async submitForm(){
      try{
        console.log(this.items);
        let response = await Common.testUrl(this.items)
        
        if(response.status == 200){
          this.responseData = response.data.data;
        }
      }catch(error){

      }
    },
  },
  data () {
    return {
      items: {
        url:'',
      },
      responseData: null
    }
  },
 
  computed: {
    formattedResponseData() {
      return JSON.stringify(this.responseData, null, 2);
    },
    allFieldsValid() {
          return this.items.url;
      },
     
  }
}
</script>
